import {MotorCCActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const MotorCCRequestInit = () => {
    return {
        type: MotorCCActionType.MotorCC_INIT
    }
}

export const MotorCCSuccess = (
    objeckt: any,
) => {
    return {
        type: MotorCCActionType.MotorCC_INIT,
        objeckt: objeckt,
    }
}

export const getMotorCC = (
    objeckt: any[],
) => {
    return (dispatch: Dispatch) => {
        dispatch(MotorCCSuccess(objeckt))
    }
}
