import {CompleteSystemWCActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const CompleteSystemWCRequestInit = () => {
    return {
        type: CompleteSystemWCActionType.CompleteSystemWC_INIT
    }
}

export const CompleteSystemWCSuccess = (
    objeckt: any
) => {
    return {
        type: CompleteSystemWCActionType.CompleteSystemWC_INIT,
        objeckt: objeckt
    }
}

export const getCompleteSystemWC = (
    objeckt: any[]
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            CompleteSystemWCSuccess(
                objeckt
            )
        )
    }
}
