import {ProducerActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const ProducerRequestInit = () => {
    return {
        type:ProducerActionType.Producer_INIT
    }
}

export const ProducerSuccess = (
    objeckt: any
) => {
    return {
        type: ProducerActionType.Producer_INIT,
        objeckt: objeckt
    }
}

export const getProducer= (
    objeckt: any[]
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            ProducerSuccess(
                objeckt
            )
        )
    }
}
