import React, { useState } from 'react'
import './@WUM/templates/default/assets/css/App.css'
import TemplateSwitcher from './@WUM/templates'
import { useSelector } from 'react-redux'
import MediaGalerie from './@WUM/core/component/const/mediaGalerie'
import TooltipModal from './@WUM/core/component/const/Tooltip'
import {
    ResponseAllNotification,
    ResponseAllNotificationUnread
} from "./@WUM/core/notification/api/response/notification";
import {NotificationService} from "./@WUM/core/notification/notification.service";
import GetRoute from "./@WUM/core/RestFullApi/local/getRoute";
import {ResponseGetRibbon} from "./@WUM/core/ribbon/API/response/ribbons";
import PostRoute from "./@WUM/core/RestFullApi/local/postRoute";

const App = () => {
    const Store = useSelector((state: any) => state)
    if (process.env.REACT_APP_WITH_NOTIFICATION !== undefined) {
        const notification = ResponseAllNotificationUnread('0');
        NotificationService(notification);
    }
    const [ribbonexist, setribbonexist] = useState(false) // Array instead of object
    const ribbon = ResponseGetRibbon('favos','ribbon','',setribbonexist);

    if(localStorage.getItem('userid') !== undefined && localStorage.getItem('userid') !== '' && ribbon === undefined) {
        PostRoute(
            {
                "id": null,
                "userid": localStorage.getItem('userid'),
                "favos": []
            }
        )
    }

    if(process.env.REACT_APP_RIBBON !== undefined) {


        if(ribbonexist !== false) {
            return (
                <div className="App">
                    <TemplateSwitcher layout={'default'} Store={Store} />
                    <MediaGalerie Store={Store} />
                    <TooltipModal />
                </div>
            )
        } else {
            return (
                <div className="App">
                    <h1>Please Wait...!</h1>
                </div>
            )
        }
    } else {
        return (
            <div className="App">
                <TemplateSwitcher layout={'default'} Store={Store} />
                <MediaGalerie Store={Store} />
                <TooltipModal />
            </div>
        )
    }

}

export default App
