import React, { useState } from 'react'
import { SpinnerJSX } from '../../../@WUM/core/component/const/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { ContentSwitch } from '../../../@WUM/core/Elements/index'
import {
    Response,
    ResponseAllMerchant,
    ResponseAllSRoles,
    ResponseDelete
} from '../API/response/member/member'
import { memberApiType } from '../API/request/member/memberApi.interface'
import IntlMessage, {
    IntlMessageValue
} from '../../../@WUM/core/component/const/IntlMessage'
import { ApiURL } from '../../../@WUM/core/component/const/ApiURL'
import { getToken } from '../../../@WUM/core/component/const/getToken'
import { useSelector } from 'react-redux'
import SearchJSX from "../../../@WUM/core/component/const/SearchJSX";

const MemberOverview = (value: any) => {
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()

    let limit: any
    if (typeof page === 'string') {
        limit = parseFloat(page) * 15 - 15
    }
    const Store = useSelector((state: any) => state)
    const [mode, setMode] = useState('cart')
    const destination = 'Member'
    const namespace_search = 'Member_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    const MerchantSelect = () => {
        const [issue, setIssue] = useState([]) // Array instead of object

        React.useEffect(() => {
            let root = ApiURL()
            const fetchData = async () => {
                const result = await fetch(root + `User/byMerchant/`, {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + getToken()
                    }
                })
                    .then((response) => response.json())
                    .then((response) => {
                        return response
                    })
                    .catch((err) => console.error(err))

                setIssue(result)
            }

            fetchData()
        }, [])

        return issue
    }

    const history = useNavigate()
    /**
     * Reload Page
     */
    const routeChange = (paths: string) => {
        history(paths)
    }

    if (deleteID) {
        ResponseDelete(deleteID)
        routeChange('/Member/overview/1')
    }
    const [search, setSearch] = useState<boolean>(false)
    const response = Response(
        limit,
        searchValue
    )
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])
    const AllMerchant = ResponseAllMerchant()
    const Roles = ResponseAllSRoles()
    const debug = (
        searchValue: any,
        page: string | undefined,
        AllMerchant: memberApiType[] | boolean,
        Roles: memberApiType[] | boolean,
        modalShow: any,
        modalData: any
    ) => {
        return
    }
    debug(searchValue, page, AllMerchant, Roles, modalShow, modalData)

    const _handleEnter = function (e: any) {
        if (e.key === 'Enter') {
            let search = ''
            let title
            let iterator = []

            title = e.target.value
            localStorage.setItem('searchInput', title)
            if (title !== undefined && title !== '') {
                iterator.push('email=' + title)
            }
            let discriminator
            discriminator = (
                document.getElementById('searchSelect') as HTMLSelectElement
            ).value
            localStorage.setItem('searchSelect', discriminator)
            if (discriminator !== undefined && discriminator !== '') {
                iterator.push(discriminator)
            }

            for (let y = 0; y < iterator.length; y++) {
                if (y === iterator.length - 1) {
                    search += iterator[y]
                } else {
                    search += iterator[y] + '&'
                }
            }
            setSearchValue('?search=1&' + search)
        }
    }

    const _handleKeyFilters = function (e: any) {
        let discriminator
        let title
        let merchant
        if (e.target.value !== '0') {
            let search = ''

            let iterator = []

            title = (document.getElementById('search') as HTMLInputElement)
                .value
            localStorage.setItem('searchInput', title)
            if (title !== undefined && title !== '') {
                iterator.push("email= like '%_" +
                title +
                "%'")
            }

            discriminator = (
                document.getElementById('searchSelect') as HTMLSelectElement
            ).value
            localStorage.setItem('searchSelect', discriminator)
            if (discriminator !== undefined && discriminator !== '') {
                iterator.push(discriminator)
            }

            /*merchant = (
                document.getElementById(
                    'searchSelectMerchant'
                ) as HTMLSelectElement
            ).value
            localStorage.setItem('searchSelectMerchant', merchant)
            if (merchant !== undefined && merchant !== '') {
                iterator.push('merchant=' + merchant)
            }*/

            for (let y = 0; y < iterator.length; y++) {
                if (y === iterator.length - 1) {
                    search += iterator[y]
                } else {
                    search += iterator[y] + '&'
                }
            }
            setSearchValue('?search=1&' + search)
        }
    }

    const searchable = [
        {
            param: {
                placeholder: 'user.search'
            }
        }/*,
        {
            option: {
                value: 'discr=admin',
                label: 'role.superadmin'
            }
        },
        {
            option: {
                value: 'discr=consultant',
                label: 'role.consultant'
            }
        },
        {
            option: {
                value: 'discr=engineer',
                label: 'role.engineer'
            }
        },
        {
            option: {
                value: 'discr=locksmith',
                label: 'role.locksmith'
            }
        }*/
    ]

    let merchant = MerchantSelect()


    const CardsConfiguration = {
        data: {
            ressource: response,
            img: '',
            status: 'is_active',
            statusActive: IntlMessageValue('de', 'common.activated', Store),
            statusDeactive: IntlMessageValue('de', 'common.deactivated', Store),
            link: 'slug',
            prelink: '',
            prelinksearch: 'translation.slug',
            multiTitle: true,
            Titles: ['first_name', 'last_name'],
            title: 'username',
            descriptionShow: true,
            description: 'email',
            data_path:'discr',
            data_mapping: [{discr:'admin',name:'role.superadmin'},{discr:'consultant',name:'role.consultant'},{discr:'engineer',name:'role.engineer'},{discr:'locksmith',name:'role.locksmith'}],
            router: 'id',
            preroute: '/Member/Detail/',
            edit: IntlMessageValue('de', 'user.edit', Store),
            delete: true,
            deleteLink: '/Member/Delete/' + page + '/[ID]',
            Dontvisited: true
        }
    }

    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: ['is_active', 'email', 'first_name', 'last_name', 'discr'],
            type: ['status', 'string', 'string', 'string', 'string']
        },
        tableHeader: [
            <IntlMessage Store={Store} messageId="common.status" />,
            <IntlMessage Store={Store} messageId="common.email" />,
            <IntlMessage Store={Store} messageId="common.name.first" />,
            <IntlMessage Store={Store} messageId="common.name.last" />,
            <IntlMessage Store={Store} messageId="permissions" />
        ],
        tableHeaderWidth: ['20%', '20%', '20%', '20%', '20%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menuItem: [
                <IntlMessage Store={Store} messageId="common.edit" />,
                <IntlMessage Store={Store} messageId="common.delete" />
            ],
            menuroute: ['/Member/Detail', '/Member/Delete'],
            menuParams: ['id', 'id'],
            menuIcons: ['pencil', 'Delete']
        }
    }

    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_emailr',
                    translation: 'common.email',
                    param: 'email',
                    method: 'like'
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'user.create'
            ]
        }
    ]

    if (response !== true && response.length > 0) {
        return (
            <>
                <SearchJSX
                    setSearchValue={setSearchValue}
                    Store={Store}
                    SearchConfig={SearchConfig}
                />
                <ContentSwitch
                    pagenew={'new'}
                    destinationnew={'Member'}
                    tonew={'new'}
                    text={<IntlMessage Store={Store} messageId="user.create" />}
                    response={response}
                    CardsConfiguration={CardsConfiguration}
                    mode={mode}
                    Configuration={Configuration}
                    setMode={setMode}
                    destination={destination}
                    page={page}
                    to={'overview'}
                    setSearchValues={setSearchValue}
                    setSearchs={setSearch}
                    search={search}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalData={modalData}
                    setModalData={setModalData}
                    searchfield={false}
                    allTaxon={''}
                    _handleKeyFilters={_handleKeyFilters}
                    _handleEnter={_handleEnter}
                    filter={false}
                    merchantSelect={merchant}
                    Store={Store}
                    SearchParam={'?email'}
                />
            </>
        )
    } else {
        localStorage.setItem('searchInput', '')
        localStorage.setItem('searchSelect', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default MemberOverview
