import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { saveDataIndividualGet } from '../../../@WUM/core/RestFullApi/ApiHook'
import { error } from '../../../@WUM/core/component/const/error.services'
import { removeUseLocalStorage } from '../../../@WUM/core/component/const/useLocalStoreage'
import { SpinnerJSX } from '../../../@WUM/core/component/const/Spinner'
import IntlMessage from '../../../@WUM/core/component/const/IntlMessage'
import {
    ResponseCalender,
    ResponseCalenderDelete
} from '../API/response/calender/calender'
import { useSelector } from 'react-redux'
import SearchJSX from '../../../@WUM/core/component/const/SearchJSX'
import TableMaker from "../../../@WUM/core/tablemaker";
import PaginationJSX from "../../../@WUM/core/form-serilizer/elements/pagination";
import {handleSucces} from "../../../@WUM/core/component/const/HandleSucces";

const PageOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    removeUseLocalStorage('seo_title')
    removeUseLocalStorage('seo_description')

    const history = useNavigate()
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths: string) => {
        let path = paths
        history(path)
    }
    let limit: any
    if (deleteID) {
        ResponseCalenderDelete(deleteID)
        routeChange('/vde/overview/' + page)
    }
    if (typeof page === 'string') {
        limit = parseFloat(page) * 15 - 15
    }
    const destination = 'vde'
    const namespace_search = 'vde_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    const [search, setSearch] = useState<boolean>(false)
    const response = ResponseCalender(limit, searchValue)
    const [parent_id, setparent_id] = useState(false)
    const row: any = []
    if (parent_id === false) {
        localStorage.removeItem('fetchSelect')
        const getPagesSelect = () => {
            saveDataIndividualGet('/Content/Pages/Select/0/')
                .then((data: any) => {
                    row.push(data)
                    localStorage.setItem('fetchSelect', JSON.stringify(row))
                })
                .catch((e) => {
                    error(e)
                })
        }
        getPagesSelect()
        setparent_id(true)
    }
    const debug = (searchs: any) => {}

    debug(search)
    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_name',
                    translation: 'common.id',
                    param: 'vde.ID',
                    method: 'like'
                },
                {
                    id: 'search_customer',
                    translation: 'common.customer',
                    param: 'kd.Name',
                    method: 'like'
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'vde.overview.button.new'
            ]
        }
    ]
    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'AUFTRAGNR',
            underdata: true,
            keys: ['vde.ID','vde.Auftragnr', 'vde.ArtP', 'vde.NaechsteP','vde.Standort','kd.Name'],
            type: ['string', 'string', 'string', 'string', 'string', 'string']
        },
        tableHeader: [
            <IntlMessage Store={Store} messageId="vde.table.id" />,
            <IntlMessage Store={Store} messageId="vde.table.invoice_number" />,
            <IntlMessage Store={Store} messageId="vde.table.kind_of_test" />,
            <IntlMessage Store={Store} messageId="vde.table.next_exam" />,
            <IntlMessage Store={Store} messageId="vde.table.location" />,
            <IntlMessage Store={Store} messageId="common.customer" />,
        ],
        tableHeaderWidth: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                <IntlMessage messageId="common.edit" Store={Store} />,
                <IntlMessage messageId="common.delete" Store={Store} />
            ],
            menuroute: [
                '/vde/Detail',
                '/vde/Delete/' + page + ''
            ],
            menuParams: ['', 'AUFTRAGNR', 'AUFTRAGNR'],
            menuIcons: ['pencil', 'Delete']
        }
    }
    if (response.length > 0) {
        return (
            <>
                <SearchJSX
                    setSearchValue={setSearchValue}
                    Store={Store}
                    SearchConfig={SearchConfig}
                />
                <TableMaker Configuration={Configuration} />
                <PaginationJSX
                    response={response}
                    history={history}
                    to={'overview'}
                    maxPage={limit}
                    handleSuccess={handleSucces}
                    destination={destination}
                />
               {/* {
                    <ContentSwitch
                        pagenew={'new'}
                        destinationnew={'Calender'}
                        tonew={'new'}
                        text={
                            <IntlMessage
                                Store={Store}
                                locale={'de'}
                                messageId={'omment.event.new'}
                            />
                        }
                        response={response}
                        CardsConfiguration={CardsConfiguration}
                        mode={mode}
                        Configuration={Configuration}
                        setMode={setMode}
                        destination={destination}
                        page={page}
                        to={'overview'}
                        setSearchValues={setSearchValue}
                        setSearchs={setSearch}
                        search={false}
                        modalShow={modalShow}
                        setModalShow={setModalShow}
                        modalData={modalData}
                        setModalData={setModalData}
                        SearchParam={'?translation.title'}
                        searchfield={false}
                        allTaxon={[]}
                        _handleKeyFilters={[]}
                        _handleEnter={[]}
                        filter={true}
                        stop={false}
                        Store={Store}
                    />
                }*/}
            </>
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default PageOverview
