import React,{JSXElementConstructor, ReactElement} from "react";
import ReactDOM from "react-dom";

interface InjectNewElementProps {
    index:number,
    SetIndex:any,
    injectElementId:string
    elements:  ReactElement<any, string | JSXElementConstructor<any>>
}

const InjectNewElement = (props:InjectNewElementProps)=> {

    let newElement = props.elements
    let temp = document.createElement('div')
    ReactDOM.render(newElement, temp)
    let ele = document.getElementById(props.injectElementId)
    if (ele !== null) {
        ele.appendChild(temp)
        props.SetIndex(props.index + 1)
    }



}

export default InjectNewElement
