import React from "react";
import { Form } from 'react-bootstrap'
import {IntlMessageValue} from "./IntlMessage";

const HTMLFetchSelect = (props:{fetch:any,name:any,label:any,value:any,id:any,Store?:any,valueProperty:any,displayProperty:any,beforedisplayProperty?:any,onchange?:boolean,func?:any}) => {

    let FetchHolder = [];

    for(let f=0;f<props.fetch.length;f++){
        FetchHolder.push(
            <>
                <option value={props.fetch[f][props.valueProperty]} key={f+ '_' + props.fetch[f][props.valueProperty]}>
                    {(props.beforedisplayProperty !== undefined && props.beforedisplayProperty !== null ? props.fetch[f][props.beforedisplayProperty] + '-' : '')}  {props.fetch[f][props.displayProperty]}
                </option>

            </>
        )
    }

    if(props.Store !== undefined) {
        return(
            <>
                <Form.Group
                    controlId={props.id}
                    key={props.name}
                    className="mb-3"
                >
                    <Form.Label>{props.label}</Form.Label>
                    <Form.Select
                        name={props.name}
                        aria-label="Floating label select example"
                        defaultValue={props.value}
                        onChange={(props.onchange === true ? (e)=>props.func(e.target.value,'wc','cc',props.Store): undefined)}
                    >
                        <option></option>
                        {FetchHolder}
                    </Form.Select>
                </Form.Group>
            </>
        )
    } else {
        return(
            <>
                <Form.Group
                    controlId={props.id}
                    key={props.name}
                    className="mb-3"
                >
                    <Form.Label>{props.label}</Form.Label>
                    <Form.Select
                        name={props.name}
                        aria-label="Floating label select example"
                        defaultValue={props.value}
                        onChange={(props.onchange === true ? (e)=>props.func(e.target.value,'wc','cc',props.Store): undefined)}
                    >
                        <option></option>
                        {FetchHolder}
                    </Form.Select>
                </Form.Group>
            </>
        )
    }



}

export default HTMLFetchSelect;


