export interface config {
    id:any
    DESTDEBNR: string
    Name1?: any
    EMail: string
    Ort: string
    Strasse?: any
    Telefax: number
    Telefon1: number
    Telefon2: number
    Sachbearbeiter1: string
    Sachbearbeiter2: string
    melden_bei: string
    KdData:any
    SUCHEN:any
    BrAnrede:any
    zuHaenden:any
    Selection1:any
    Selection2:any
    Branche:any
    UstId1:any
    KzPreisPfl:any
    Psf:any
    Plz:any
    SpKzJn:any
    Bemerkungen:any
}

export function output(Data: any) {
    let back = {
        id:Data.id,
        DESTDEBNR: Data.DESTDEBNR,
        Name1: Data.Name1,
        EMail: Data.EMail,
        Ort: Data.Ort,
        Strasse: Data.Strasse,
        Telefax: Data.Telefax,
        Telefon1: Data.Telefon1,
        Telefon2: Data.Telefon2,
        Sachbearbeiter1: Data.Sachbearbeiter1,
        Sachbearbeiter2: Data.Sachbearbeiter2,
        melden_bei: Data.melden_bei,
        KdData:Data.KdData,
        SUCHEN:Data.SUCHEN,
        BrAnrede:Data.BrAnrede,
        zuHaenden:Data.zuHaenden,
        Selection1:Data.Selection1,
        Selection2:Data.Selection2,
        Branche:Data.Branche,
        UstId1:Data.UstId1,
        KzPreisPfl:Data.KzPreisPfl,
        Psf:Data.Psf,
        Plz:Data.Plz,
        SpKzJn:Data.SpKzJn,
        Bemerkungen:Data.Bemerkungen
    }

    return back
}
