import { useEffect, useState } from 'react'
import { calenderApiType } from '../../request/calender/calenderApi.interface'
import { calender } from '../../request/calender/calender'
import { calenderApiDetail } from '../../request/calender/calenderApiDetail.interface'
import { error } from '../../../../../@WUM/core/component/const/error.services'
import { calenderApiOutlookcheck } from "../../request/calender/calenderApiOutlookcheck.interface";
import { calenderApiOutlookDevice } from "../../request/calender/calenderApiOutlookDevice.interface";
import {CalenderEventInterface} from "../../request/calender/CalenderEvent.interface";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";

export const ResponseCalender = (limit: string, searchValue: any) => {
    const [posts, setPosts] = useState<calenderApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
                .getPosts(limit, searchValue)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

export const ResponseCalenderDetail = (id: any) => {
    const [posts, setPosts] = useState<calenderApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
                .getAPost(id)
                .then((data) => {
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}

export const ResponseCalenderDelete = (id: any) => {
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
                .deletePost(id)
                .then((data) => {
                    return data
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : isError
}


export const ResponseCalenderCheck= (uid: any) => {
    const [posts, setPosts] = useState<calenderApiOutlookcheck>({state: 0})
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
              .checkUser(uid)
              .then((data) => {
                  return setPosts(data)
              })
              .catch((err) => {
                  error(err)
                  setIsError(true)
              })
        })()
    }, [])

    return isError ? posts : posts
}

export const ResponseCalenderDevice= () => {
    const [posts, setPosts] = useState<null| calenderApiOutlookDevice>(null)
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
              .deviceCall()
              .then((data) => {
                  return setPosts(data)
              })
              .catch((err) => {
                  error(err)
                  setIsError(true)
              })
        })()
    }, [])

    return isError ? posts : posts
}
export const ResponseALLUser = (id: string) => {
    const [posts, setPosts] = useState<calenderApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
                .AllUser(id)
                .then((data) => {
                    localStorage.setItem('AllUSerCalendder', JSON.stringify(data))
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}

export const ResponseUserCalender = (id: string,start:string,end:string,users:any) => {
    const [posts, setPosts] = useState<CalenderEventInterface[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    let tmp = ''

    for(let x =0;x<users.length;x++) {
        if(x === users.length -1) {
            tmp = tmp + users[x].id
        } else {
            tmp = tmp +users[x].id+'-'
        }
    }

    useEffect(() => {
        ;(async () => {
            await calender

                .getAPostCalender(id,start,end,tmp)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,start,end,users])

    return isError ? posts : posts
}
