export interface config {
    vde: []
    ag: []
    kd: []
    IHRE_BESTE:any,
    PRODUKTNR : string
    SERIENNR: string
    HERSTELLER: string
    Standort: string
    DATUM: string
    DATUMSA:string
    TECHNIKER: string
    TERMIN:string
}

export function output(Data: any) {
    let back = {
        vde: Data.vde,
        ag: Data.ag,
        kd: Data.kd,
        IHRE_BESTE:Data.IHRE_BESTE,
        PRODUKTNR : Data.PRODUKTNR,
        SERIENNR: Data.SERIENNR,
        HERSTELLER: Data.HERSTELLER,
        Standort: Data.Standort,
        DATUM: Data.DATUM,
        DATUMSA: Data.DATUMSA,
        TECHNIKER: Data.TECHNIKER,
        TERMIN: Data.TERMIN
    }

    return back
}
