import React from 'react'
import PielersForm from '../../form-serilizer'
import Button from 'react-bootstrap/Button'
import './copyElements.css'

const OutlookCalenderDeviceRequest = (id: any, value:any, code:any, Element: any, store: any, shoDeviceUrl: any, showDeviceRequest: any) => {
    const configuration = [
        {
            formType: 'input',
            type: 'text',
            name: 'mail',
            value: value,
            placeholder: "Microsoft Email angeben"
        },

    ]



    const closeElements = () => {
        let modal = document.getElementById(
            'ModalOutlookDevice'
        ) as HTMLDivElement
        modal.style.display = 'none'
    }

    const FetchElement = () => {
        console.log(showDeviceRequest)
        showDeviceRequest();
        /*let groupDisbled = ['Site' + id, 'Blog' + id]
        let typeID = null
        for (let x = 0; x < groupDisbled.length; x++) {
            let element = document.getElementsByName(
                groupDisbled[x]
            )[0] as HTMLSelectElement
            if (element.value !== '0') {
                typeID = element.value
            }
        }
        if (typeID !== null) {
            saveDataIndividualPut(
                '/elements/copy/' + id + '/' + typeID + '/',
                ''
            )
                .then((data: any) => {
                    let modal = document.getElementById(
                      'ModalOutlookDevice'
                    ) as HTMLDivElement
                    modal.style.display = 'none'
                })
                .catch((e: any) => {
                    error(e)
                })
        } else {
            notify_copy_Element_error(store)
        }*/
    }

    return (
        <>
            <div id={'ModalOutlookDevice'} className="modal modal-copy">
                <div className="modal-content modal-content-outlook" >
                    <div>
                        <span
                            className="close-copy float-end"
                            onClick={() => closeElements()}
                        >
                            &times;
                        </span>
                    </div>
                    <div
                        className={'MainBackground'}
                        style={{ backgroundColor: 'white', padding: '15px'}}
                        key={'button'}
                    >
                        <h3>{Element}</h3>
                        {PielersForm(configuration)}
                        <p>{code}</p>
                        <Button
                            variant={'success'}
                            onClick={() => FetchElement()}
                        >
                            Anfrage Stellen
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OutlookCalenderDeviceRequest
