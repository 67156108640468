import { OotherSCWCActionType } from '../../actions/ActionType'

const initialState = {
    loading: false,
    objeckt:[]
}

type action = {
    type: string
    objeckt?: any
}

const OotherSCWCReducer = (state = initialState, action: action) => {
    switch (action.type) {
        case OotherSCWCActionType.OotherSCWC_INIT:
            return {
                ...state,
                objeckt:action.objeckt,
                loading: true
            }
        default:
            return state
    }
}

export default OotherSCWCReducer
