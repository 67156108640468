import IntlMessage, {IntlMessageValue} from '../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'
import general from "./general"
import PielersForm from "../../../../@WUM/core/form-serilizer";
import showCheck from "./showcheck";
import ski from "./ski";
import skii from "./skii";
import rpe from "./rpe";
import Riso from "./Riso";
import IEA from "./IEA";
import IPE from "./IPE";
import IBR from "./IBR";
import stats from "./stats";
import table from "./table";
import DataList from "../../../../@WUM/core/component/const/DataList";
function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any,
    currenttab:any,
    setcurrenttab:any
) {


    const titel = (
        <>

        </>
    )

    const description = (
        <>

        </>
    )

    let dataColOne = [
        {
            formType: 'input',
            type: 'hidden',
            name: 'id',
            value: newElement === false ? response.id : '0'
        },
        {
            formType: 'multiply',
            cols: [
        {
            formType: 'input',
            label: IntlMessageValue('de', 'common.vde.invoice_number', Store),
            required: true,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.vde.invoice_number.provide', Store),
            name: 'vde[Auftragnr]',
            readonly: newElement === false ? true : false,
            value: newElement === false ? response.vde.Auftragnr : ''
        },
        {
            formType: 'input',
            label: IntlMessageValue('de', 'common.vde.home.artp', Store),
            required: true,
            type: 'text',
            readonly: newElement === false ? true : false,
            placeholder: IntlMessageValue('de', 'common.vde.home.artp.provide', Store),
            name: 'vde[ArtP]',
            value: newElement === false ? response.vde.ArtP : ''
        },
                ]},
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'DataList',
                    label: IntlMessageValue('de', 'common.sa.client', Store),
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.client.provide', Store),
                    name: 'ag[KdNr]',
                    fetch: Store.Agd.objeckt,
                    value1:'KdNr',
                    value2: 'Name',
                    id:'test1',
                    idSet:[
                        {type:'textarea',id:'agd_adress0',key:false, keyMap:['Name','Strasse','Ort']},
                        {type:'input',id:'ag_please_contact',key:'melden_bei'},
                        {type:'input',id:'agd_tel',key:'Telefon1'},
                        {type:'input',id:'agd_tel2',key:'Telefon2'},
                        {type:'input',id:'agd_fax',key:'Telefon1'},
                        {type:'input',id:'agd_mail',key:'EMail'},
                        {type:'input',id:'ag_sachbearbeiter1',key:'Sachbearbeiter1'},
                        {type:'input',id:'ag_sachbearbeiter2',key:'Sachbearbeiter2'}],
                    value: newElement === false ? response.ag.KdNr : ''
                },
                {
                    formType: 'DataList',
                    label: IntlMessageValue('de', 'common.vde.kdnr', Store),
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.kdnr.provide', Store),
                    name: 'kd[KdNr]',
                    fetch: Store.Kd.objeckt,
                    value1:'KdNr',
                    value2: 'Name',
                    id:'test3',
                    idInput:'test4',
                    idSet:[
                        {type:'textarea',id:'kd_adress1',key:false, keyMap:['Name','Strasse','Ort']},
                        {type:'input',id:'kd_please_contact',key:'melden_bei'},
                        {type:'input',id:'kd_tel',key:'Telefon1'},
                        {type:'input',id:'kd_tel2',key:'Telefon2'},
                        {type:'input',id:'kd_fax',key:'Telefon2'},
                        {type:'input',id:'kd_mail',key:'EMail'},
                        {type:'input',id:'kd_sachbearbeiter1',key:'Sachbearbeiter1'},
                        {type:'input',id:'kd_sachbearbeiter2',key:'Sachbearbeiter2'}],
                    value: newElement === false ? response.ag.KdNr : ''
                }
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'textarea',
                    label: '',
                    type: 'textarea',
                    max: 800,
                    name: '',
                    id: 'agd_adress',
                    placeholder: '',
                    disabled: newElement === false ? true : false,
                    row:3,
                    value:
                        newElement === false
                            ? response !== undefined && response !== null
                                ? response.ag.Name + '\n' + response.ag.Strasse + '\n' + response.ag.Ort
                                : ''
                            : ''
                },
                {
                    formType: 'textarea',
                    label: '',
                    type: 'textarea',
                    max: 800,
                    name: '',
                    id: 'kd_adress',
                    disabled: newElement === false ? true : false,
                    placeholder: '',
                    row:3,
                    value:
                        newElement === false
                            ? response !== undefined && response !== null
                                ? response.kd.Name + '\n' + response.kd.Strasse + '\n' + response.kd.Ort
                                : ''
                            : ''
                },
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.sb', Store),
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.sb.provide', Store),
                    name: 'ag[Sachbearbeiter1]',
                    id:'ag_sachbearbeiter1',
                    value: newElement === false ? response.ag.Sachbearbeiter1 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tel', Store),
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.tel.provide', Store),
                    name: 'ag[Telefon1]',
                    id:'agd_tel',
                    value: newElement === false ? response.ag.Telefon1 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.sb', Store),
                    required: true,
                    readonly: newElement === false ? true : false,
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.sb.provide', Store),
                    name: 'kd[Sachbearbeiter1]',
                    id:'kd_sachbearbeiter1',
                    value: newElement === false ? response.kd.Sachbearbeiter1 : ''
                },{
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tel', Store),
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.tel.provide', Store),
                    name: 'kd[Telefon1]',
                    id:'kd_tel',
                    value: newElement === false ? response.kd.Telefon1 : ''
                }
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.sb', Store),
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.sb.provide', Store),
                    name: 'ag[Sachbearbeiter2]',
                    id:'ag_sachbearbeiter2',
                    value: newElement === false ? response.ag.Sachbearbeiter2 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.mobil', Store),
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.mobil.provide', Store),
                    name: 'ag[Telefon2]',
                    id:'agd_tel2',
                    value: newElement === false ? response.ag.Telefon2 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.sb', Store),
                    required: true,
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.sb.provide', Store),
                    name: 'kd[Sachbearbeiter2]',
                    id:'kd_sachbearbeiter2',
                    value: newElement === false ? response.kd.Sachbearbeiter2 : ''
                },{
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.mobil', Store),
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.mobil.provide', Store),
                    name: 'kd[Telefon2]',
                    id:'kd_tel2',
                    value: newElement === false ? response.kd.Telefon2 : ''
                }
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.mb', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.mb.provide', Store),
                    name: 'ag[melden_bei]',
                    id:'ag_please_contact',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.ag.melden_bei : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.fax', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.fax.provide', Store),
                    name: 'ag[Telefax]',
                    id:'agd_fax',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.ag.Telefax : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.mb', Store),
                    required: true,
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.mb.provide', Store),
                    name: 'kd[melden_bei]',
                    id:'kd_please_contact',
                    value: newElement === false ? response.kd.melden_bei : ''
                },{
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.fax', Store),
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.fax.provide', Store),
                    name: 'kd[Telefax]',
                    id:'kd_fax',
                    value: newElement === false ? response.kd.Telefax : ''
                }
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.email', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.email.provide', Store),
                    name: 'ag[EMail]',
                    id:'agd_mail',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.ag.EMail : ''
                },
                {
                    formType: 'clear',
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.email', Store),
                    required: true,
                    readonly: newElement === false ? true : false,
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.email.provide', Store),
                    name: 'kd[EMail]',
                    id:'kd_mail',
                    value: newElement === false ? response.kd.EMail : ''
                },{
                    formType: 'clear',
                }
            ]
        },


    ]

    let dataColOnes;

    if(newElement !== 'debug') {
        dataColOnes = [
            ...dataColOne,
            {
                formType: 'multiply',
                RowId:'head1GdP',
                cols: [
                    {
                        formType: 'input',
                        label: IntlMessageValue('de', 'common.vde.reason_for_the_exam', Store),
                        type: 'text',
                        readonly: newElement === false ? true : false,
                        placeholder: IntlMessageValue('de', 'common.vde.reason_for_the_exam.provide', Store),
                        name: 'vde[ArtP]',
                        value: newElement === false ? response.vde.ArtP : ''
                    },
                ]
            },
            {
                formType: 'multiply',
                RowId:'head2general',
                cols: [
                    {
                        formType: 'input',
                        label: IntlMessageValue('de', 'common.vde.device', Store),
                        type: 'text',
                        readonly: newElement === false ? true : false,
                        placeholder: IntlMessageValue('de', 'common.vde.device.provide', Store),
                        name: '',
                        value: newElement === false ? response.Pruefgeraet1 : ''
                    },
                    {
                        formType: 'input',
                        label: IntlMessageValue('de', 'common.vde.id', Store),
                        required: true,
                        readonly: newElement === false ? true : false,
                        type: 'text',
                        placeholder: IntlMessageValue('de', 'common.vde.id.provide', Store),
                        name: 'vde[ID]',
                        value: newElement === false ? response.vde.ID : ''
                    },
                    {
                        formType: 'input',
                        label: IntlMessageValue('de', 'common.vde.protection_class', Store),
                        type: 'text',
                        readonly: newElement === false ? true : false,
                        placeholder: IntlMessageValue('de', 'common.vde.protection_class.provide', Store),
                        name: 'vde[SK]',
                        value: newElement === false ? response.vde.SK : ''
                    }
                ]
            },
            {
                formType: 'TabMaker',
                startEvent: 'allgemein',
                currenttab:  currenttab,
                setter:setcurrenttab,
                class:'changeMT',
                tabs: [
                    {'eventKey': 'allgemein','title':IntlMessageValue('de', 'common.vde.tab.general', Store),'key':'allgemein','id':'allgemein','content':  PielersForm(general(conf,response,newElement,Store,
                            setcurrenttab))},
                    {'eventKey': 'showcheck','title':IntlMessageValue('de', 'common.vde.tab.showcheck', Store),'key':'showcheck','id':'showcheck','content':PielersForm(showCheck(conf,response,newElement,Store, setcurrenttab))},
                    {'eventKey': 'ski','title':IntlMessageValue('de', 'common.vde.tab.ski', Store),'key':'ski','id':'ski','content': PielersForm(ski(conf,response,newElement,Store,setcurrenttab))},
                    {'eventKey': 'skii','title':IntlMessageValue('de', 'common.vde.tab.skii', Store),'key':'skii','id':'skii','content': PielersForm(skii(conf,response,newElement,Store,setcurrenttab))},
                    {'eventKey': 'rpe','title':IntlMessageValue('de', 'common.vde.tab.rpe', Store),'key':'rpe','id':'rpe','content': PielersForm(rpe(conf,response,newElement,Store,setcurrenttab))},
                    {'eventKey': 'Riso','title':IntlMessageValue('de', 'common.vde.tab.riso', Store),'key':'Riso','id':'Riso','content': PielersForm(Riso(conf,response,newElement,Store,setcurrenttab))},
                    {'eventKey': 'IEA','title':IntlMessageValue('de', 'common.vde.tab.iea', Store),'key':'IEA','id':'IEA','content': PielersForm(IEA(conf,response,newElement,Store,setcurrenttab))},
                    {'eventKey': 'IPE','title':IntlMessageValue('de', 'common.vde.tab.ipe', Store),'key':'IPE','id':'IPE','content': PielersForm(IPE(conf,response,newElement,Store,setcurrenttab))},
                    {'eventKey': 'IBR','title':IntlMessageValue('de', 'common.vde.tab.ibr', Store),'key':'IBR','id':'IBR','content': PielersForm(IBR(conf,response,newElement,Store,setcurrenttab))},
                    {'eventKey': 'Auswertung','title':IntlMessageValue('de', 'common.vde.tab.evaluation', Store),'key':'Auswertung','id':'Auswertung','content': PielersForm(stats(conf,response,newElement,Store,setcurrenttab))},
                    {'eventKey': 'Tabelle','title':IntlMessageValue('de', 'common.vde.tab.table', Store),'key':'Tabelle','id':'Tabelle','content': PielersForm(table(conf,response,newElement,Store))},
                    {'eventKey': 'Ausdruck','title':IntlMessageValue('de', 'common.vde.tab.expression', Store),'key':'Ausdruck','id':'Ausdruck','content': <>Muss PDF Tool hin</>}
                ],
            },
        ]
    } else {
        dataColOnes = [...dataColOne];
    }

    if (conf === 'titel') {
        return titel
    } else if (conf === 'description') {
        return description
    } else if (conf === 'dataColOne') {
        return dataColOnes
    } else if (conf === 'dataColtwo') {
        return;
    }
}

export default configuration
