import {CompleteSystemCCActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const CompleteSystemCCRequestInit = () => {
    return {
        type: CompleteSystemCCActionType.CompleteSystemCC_INIT
    }
}

export const CompleteSystemCCSuccess = (
    objeckt: any,
) => {
    return {
        type: CompleteSystemCCActionType.CompleteSystemCC_INIT,
        objeckt: objeckt,
    }
}

export const getCompleteSystemCC = (
    objeckt: any[],
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            CompleteSystemCCSuccess(
                objeckt
            )
        )
    }
}
