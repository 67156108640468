import {ApplicationActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const ApplicationRequestInit = () => {
    return {
        type:ApplicationActionType.Application_INIT
    }
}

export const ApplicationSuccess = (
    objeckt: any
) => {
    return {
        type: ApplicationActionType.Application_INIT,
        objeckt: objeckt
    }
}

export const getApplication = (
    objeckt: any[]
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            ApplicationSuccess(
                objeckt
            )
        )
    }
}
