import { calenderApiType } from './calenderApi.interface'
import { requests } from '../../../../../@WUM/core/RestFullApi/api'
import { calenderApiDetail } from './calenderApiDetail.interface'
import {CalenderEventInterface} from "./CalenderEvent.interface";

export const calender = {
    getPosts: (
        limit: string,
        searchValue: string
    ): Promise<calenderApiType[]> =>
        requests.get(`kd/all/${limit}/${searchValue}`),
    getAPost: (id: string): Promise<calenderApiDetail[]> =>
        requests.get(`/kd/${id}/`),
    createPost: (post: calenderApiType): Promise<calenderApiType> =>
        requests.post('posts', post),
    updatePost: (post: any, id: number): Promise<calenderApiDetail> =>
        requests.put(`/kd/save/${id}/`, post),
    updatePostNew: (post: any, id: number): Promise<calenderApiDetail> =>
        requests.put(`/kd/new/${id}/`, post),
    deletePost: (id: string): Promise<void> =>
        requests.delete(`/kd/${id}/`),
    checkUser: (uid: string): Promise<any> =>
      requests.get(`/outlook/check/user/${uid}/`),
    deviceCall: (): Promise<any> =>
      requests.get('/outlook/device/o2autch/'),
        AllUser: (id: string): Promise<calenderApiDetail[]> =>
      requests.get(`/User/fetch/`),
    getAPostCalender: (id: string,start:string,end:string,users:string): Promise<CalenderEventInterface[]> =>
        requests.get(`/outlook/user/events/${id}/?filter=range&start=${start}&end=${end}&users=${users}`),
}
