import MergeTwoObjecttoArray from '../../../@WUM/core/component/const/MergeTwoObjecttoArray'
import components from '../../../Register/RegisterComponents'
import SecureCheck from '../../../@WUM/core/RoleSecury'
import {dashboardservice} from "../../calender";

const configruation = (Store: any) => {
    let config: any = []
    let adminIndividual: any[]

    config = MergeTwoObjecttoArray(config, dashboardservice())


    adminIndividual = []
    if (
        process.env.REACT_APP_DB_MODULS !== undefined &&
        Store.dynamikModuls.Dashboard.length > 0 &&
        !Store.dynamikModuls.Dashboard[0].hasOwnProperty('msg')
    ) {
        for (let x = 0; x < Store.dynamikModuls.Dashboard.length; x++) {
            let search = Store.dynamikModuls.Dashboard[x].namespace

            adminIndividual = MergeTwoObjecttoArray(
                adminIndividual,
                // @ts-ignore
                components[search]()
            )
        }
    }

    let newConfig = []
    let newAdminIndividual = []
    for (let x = 0; x < config.length; x++) {
        if (SecureCheck(config[x].roles)) {
            newConfig.push(config[x])
        }
    }

    for (let x = 0; x < adminIndividual.length; x++) {
        if (SecureCheck(adminIndividual[x].roles)) {
            newAdminIndividual.push(adminIndividual[x])
        }
    }

    if (process.env.REACT_APP_DB_MODULS === undefined) {
        return newConfig
    } else {
        if (localStorage.getItem('userRole') === 'superadmin') {
            return [...newConfig, ...newAdminIndividual]
        } else {
            return newAdminIndividual
        }
    }
}

export default configruation

