import {IntlMessageValue} from "../../../../@WUM/core/component/const/IntlMessage";

const stats = (conf: string,
             response: any,
             newElement: any = false,
             Store: any,
               setcurrenttab:any) => {
    return [
        {
            formType: 'header',
            value: IntlMessageValue('de', 'common.stats.tab.header', Store),
            name: 'visible',
        },
        {
            formType: 'select',
            label: IntlMessageValue('de', 'common.stats.tab.shwocheck', Store),
            name: 'vde[SichtP]',
            disabled: newElement === false ? true : false,
            selected: newElement === false ? response.vde.SichtP : 0,
            options: [
                {
                    label: '',
                    value: ''
                },
                {
                    label: IntlMessageValue('de', 'common.stats.tab.shwocheck.yes', Store),
                    value: 'WAHR'
                },
                {
                    label: IntlMessageValue('de', 'common.stats.tab.shwocheck.no', Store),
                    value: ''
                }
            ]
        },{
            formType: 'select',
            label: IntlMessageValue('de', 'common.stats.tab.rpe', Store),
            name: 'vde[RPE]',
            disabled: newElement === false ? true : false,
            selected: newElement === false ? response.vde.RPE : 0,
            options: [
                {
                    label: '',
                    value: ''
                },
                {
                    label: IntlMessageValue('de', 'common.stats.tab.rpe.yes', Store),
                    value: 'WAHR'
                },
                {
                    label: IntlMessageValue('de', 'common.stats.tab.rpe.no', Store),
                    value: ''
                }
            ]
        },
        {
            formType: 'select',
            label: IntlMessageValue('de', 'common.stats.tab.riso', Store),
            name: 'vde[RISO]',
            disabled: newElement === false ? true : false,
            selected: newElement === false ? response.vde.RISO : 0,
            options: [
                {
                    label: '',
                    value: ''
                },
                {
                    label:  IntlMessageValue('de', 'common.stats.tab.riso.yes', Store),
                    value: 'WAHR'
                },
                {
                    label: IntlMessageValue('de', 'common.stats.tab.riso.no', Store),
                    value: ''
                }
            ]
        },
        {
            formType: 'select',
            label: IntlMessageValue('de', 'common.stats.tab.ipe', Store),
            name: 'vde[IPE]',
            disabled: newElement === false ? true : false,
            selected: newElement === false ? response.vde.IPE : 0,
            options: [
                {
                    label: '',
                    value: ''
                },
                {
                    label:  IntlMessageValue('de', 'common.stats.tab.ipe.yes', Store),
                    value: 'WAHR'
                },
                {
                    label: IntlMessageValue('de', 'common.stats.tab.ipe.no', Store),
                    value: ''
                }
            ]
        },
        {
            formType: 'select',
            label: IntlMessageValue('de', 'common.stats.tab.iea', Store),
            name: 'vde[IEA]',
            disabled: newElement === false ? true : false,
            selected: newElement === false ? response.vde.IEA : 0,
            options: [
                {
                    label: '',
                    value: ''
                },
                {
                    label: IntlMessageValue('de', 'common.stats.tab.iea.yes', Store),
                    value: 'WAHR'
                },
                {
                    label: IntlMessageValue('de', 'common.stats.tab.iea.no', Store),
                    value: ''
                }
            ]
        },
        {
            formType: 'select',
            label: IntlMessageValue('de', 'common.stats.tab.ibr', Store),
            name: 'vde[IBR]',
            disabled: newElement === false ? true : false,
            selected: newElement === false ? response.vde.IBR : 0,
            options: [
                {
                    label: '',
                    value: ''
                },
                {
                    label: IntlMessageValue('de', 'common.stats.tab.ibr.yes', Store),
                    value: 'WAHR'
                },
                {
                    label: IntlMessageValue('de', 'common.stats.tab.ibr.no', Store),
                    value: ''
                }
            ]
        },
        {
            formType: 'header',
            value: IntlMessageValue('de', 'common.stats.tab.header.second', Store),
        },
        {
            formType: 'multiply',
            class:'mb-5',
            cols: [
                {
                    formType: 'select',
                    label: IntlMessageValue('de', 'common.stats.tab.functioncheck', Store),
                    name: 'vde[FktPr]',
                    disabled: newElement === false ? true : false,
                    selected: newElement === false ? response.vde.FktPr : 0,
                    options: [
                        {
                            label: '',
                            value: ''
                        },
                        {
                            label: IntlMessageValue('de', 'common.stats.tab.functioncheck.yes', Store),
                            value: 'WAHR'
                        },
                        {
                            label: IntlMessageValue('de', 'common.stats.tab.functioncheck.no', Store),
                            value: ''
                        }
                    ]
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.stats.tab.voltage', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.stats.tab.voltage.provide', Store),
                    name: 'vde[FktPrIw]',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.vde.FktPrIw : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.stats.tab.power', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.stats.tab.power.provide', Store),
                    name: 'vde[FktPrP]',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.vde.FktPrP : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.stats.tab.performance', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.stats.tab.performance.provide', Store),
                    name: 'vde[FktPrU]',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.vde.FktPrU : ''
                },


            ]
        },
        {
            formType: 'header',
            value: IntlMessageValue('de', 'common.stats.tab.header.three', Store),
        },
        {
            formType: 'multiply',
            class:'mb-5',
            cols: [
                {
                    formType: 'select',
                    label: IntlMessageValue('de', 'common.stats.tab.exam_result', Store),
                    name: 'vde[GesamtPr]',
                    disabled: newElement === false ? true : false,
                    selected: newElement === false ? response.vde.GesamtPr : 0,
                    options: [
                        {
                            label: '',
                            value: ''
                        },
                        {
                            label: IntlMessageValue('de', 'common.stats.tab.exam_result.yes', Store),
                            value: 'WAHR'
                        },
                        {
                            label: IntlMessageValue('de', 'common.stats.tab.exam_result.no', Store),
                            value: ''
                        }
                    ]
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.stats.tab.city', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.stats.tab.city.provide', Store),
                    name: 'vde[Standort]',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.vde.Standort : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.stats.tab.next_check', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.stats.tab.next_check.provide', Store),
                    name: 'vde[NaechsteP]',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.vde.NaechsteP : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.stats.tab.data', Store),
                    type: 'date',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.stats.tab.data.provide', Store),
                    name: 'vde[DATUM]',
                    value: newElement === false ? response.vde.DATUM : ''
                },


            ]
        },
        {
            formType: 'textarea',
            label: IntlMessageValue('de', 'common.stats.tab.remarks', Store),
            type: 'textarea',
            disabled: newElement === false ? true : false,
            max: 800,
            name: 'vde[GesamtPrBem]',
            id: 'seo_title',
            placeholder: '',
            value:
                newElement === false
                    ? response !== undefined && response !== null
                        ? response.vde.GesamtPrBem
                        : ''
                    : ''
        },
        {
            formType: 'textarea',
            label: IntlMessageValue('de', 'common.stats.tab.intern_remarks', Store),
            type: 'textarea',
            max: 250,
            disabled: newElement === false ? true : false,
            name: 'translation[seo][seo_title]',
            id: 'seo_title',
            placeholder: '',
            value:
                newElement === false
                    ? response !== undefined && response !== null
                        ? ''
                        : ''
                    : ''
        },
        {
            formType: 'buttonTabeMaker',
            next:'allgemein',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.vde.tab.showcheck.button.general', Store),
        },
        {
            formType: 'buttonTabeMaker',
            next:'skii',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.vde.tab.showcheck.button.protection_class_II', Store),
            class: 'float-end mr-3'
        },
        {
            formType: 'buttonTabeMaker',
            next:'ski',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.vde.tab.showcheck.button.protection_class_I', Store),
            class: 'float-end mr-3'
        }
    ]
}

export default stats;
