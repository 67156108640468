import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ContentSwitch } from '../../../@WUM/core/Elements/index'
import { saveDataIndividualGet } from '../../../@WUM/core/RestFullApi/ApiHook'
import { error } from '../../../@WUM/core/component/const/error.services'
import { removeUseLocalStorage } from '../../../@WUM/core/component/const/useLocalStoreage'
import { SpinnerJSX } from '../../../@WUM/core/component/const/Spinner'
import IntlMessage from '../../../@WUM/core/component/const/IntlMessage'
import {
    ResponseCalender,
    ResponseCalenderDelete
} from '../API/response/calender/calender'
import { useSelector } from 'react-redux'
import SearchJSX from '../../../@WUM/core/component/const/SearchJSX'
import TableMaker from "../../../@WUM/core/tablemaker";
import PaginationJSX from "../../../@WUM/core/form-serilizer/elements/pagination";
import {handleSucces} from "../../../@WUM/core/component/const/HandleSucces";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

const PageOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    removeUseLocalStorage('seo_title')
    removeUseLocalStorage('seo_description')

    const history = useNavigate()
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths: string) => {
        let path = paths
        history(path)
    }
    let limit: any
    if (deleteID) {
        ResponseCalenderDelete(deleteID)
        routeChange('/vde/overview/' + page)
    }
    if (typeof page === 'string') {
        limit = parseFloat(page) * 15 - 15
    }
    const destination = 'serviceauftragGrundfos'
    const namespace_search = 'serviceauftragGrundfos_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    const [search, setSearch] = useState<boolean>(false)
    const response = ResponseCalender(limit, searchValue)
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])
    const [mode, setMode] = useState('cart')
    const [parent_id, setparent_id] = useState(false)
    const row: any = []
    if (parent_id === false) {
        localStorage.removeItem('fetchSelect')
        const getPagesSelect = () => {
            saveDataIndividualGet('/Content/Pages/Select/0/')
                .then((data: any) => {
                    row.push(data)
                    localStorage.setItem('fetchSelect', JSON.stringify(row))
                })
                .catch((e) => {
                    error(e)
                })
        }
        getPagesSelect()
        setparent_id(true)
    }
    const debug = (searchs: any) => {}

    debug(search)
    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_customer',
                    translation: 'common.customer',
                    param: 'kd.Name',
                    method: 'like'
                },
                {
                    id: 'search_EMail',
                    translation: 'sa.grundfos.table.order_number',
                    param: 'AUFTRAGNR',
                    method: 'like'
                }, {
                    id: 'search_STATUS',
                    translation: 'Status',
                    param: 'STATUS',
                    method: 'like',
                    individual:(
                        <Form.Group
                            as={Col}
                            controlId={'search_STATUS'}
                            name={'search_STATUS'}
                        >
                            <Form.Label>STATUS</Form.Label>
                            <Form.Select aria-label="Default select example" name={'STATUS'} defaultValue={
                                localStorage.getItem(
                                    namespace_search + '_search_STATUS'
                                ) as string
                            }>
                                <option label={'Bitte Wählen'}/>
                                <option value="0 Erfasst">Erfasst</option>
                                <option value="7 Reparatur erledigt">Reparatur erledigt</option>
                                <option value="9 Geschlossen">Abgeschlossen und Abgerechnet</option>
                            </Form.Select>
                        </Form.Group>
                    )
                },
                {
                    id: 'search_Dont_completed_STATUS',
                    translation: 'Nicht abgeschlossen',
                    param: 'STATUS',
                    method: 'not like',
                    individual:(
                        <Form.Group
                            as={Col}
                            controlId={'search_Dont_completed_STATUS'}
                            name={'search_STATUS'}
                        >
                            <Form.Label>Filter</Form.Label>
                            <Form.Select aria-label="Default select example" name={'STATUS'} defaultValue={
                                localStorage.getItem(
                                    namespace_search + '_search_Dont_completed_STATUS'
                                ) as string
                            }>
                                <option label={'Bitte Wählen'}/>
                                <option value="9 Geschlossen">Nicht abgeschlossen</option>
                            </Form.Select>
                        </Form.Group>
                    )
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'sa.grundfos.overview.button.new'
            ]
        }
    ]

    const CardsConfiguration = {
        data: {
            ressource: response,
            indiBild: true,
            img: 'cdn_url',
            status: 'visible',
            statusActive: (
                <IntlMessage messageId="common.activated" Store={Store} />
            ),
            statusDeactive: (
                <IntlMessage messageId="common.deactivated" Store={Store} />
            ),
            link: '',
            prelink: 'http://myhomice-f2.pielers.de/produkte/',
            title: 'AUFTRAGNR',
            descriptionShow: true,
            description: 'AUFTRAGSAR',
            parentShow: true,
            parent: 'parent.translations.title',
            router: 'AUFTRAGNR',
            preroute: '/serviceauftrag/Detail/',
            edit: 'Serviceauftrag Grundfos ',
            delete: true,
            Dontvisited: true,
            AddressDontShowGF: true,
            AddressShow: true,
            Address: [
                {
                    street: 'PRODFAM1',
                    plz: 'PRODUKT',
                    city: 'PRODUKTNR',
                    HRB: 'NL_VB_VBA',
                    gffirstname: 'Telefax',
                    gflastname: 'Telefon1'
                }
            ],
            deleteLink: '/serviceauftragGrundfos/Delete/' + page + '/[ID]'
        }
    }

    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: ['AUFTRAGNR','STATUS', 'FEHLERANGA','PRODUKT','PRODUKTNR','kd.Name'],
            type: ['string', 'BadgeGrundfos','string', 'Twofields|PROD_GRUPP|PRODUKT', 'string','string']
        },
        tableHeader: [
            <IntlMessage Store={Store} messageId="sa.grundfos.table.order_number" />,
            'Status',
            <IntlMessage Store={Store} messageId="sa.grundfos.table.error_statement" />,
            <IntlMessage Store={Store} messageId="sa.grundfos.table.produkt" />,
            <IntlMessage Store={Store} messageId="sa.grundfos.table.produkt_number" />,
            <IntlMessage Store={Store} messageId="common.customer" />,
        ],
        tableHeaderWidth: ['auto', 'auto', 'auto','auto', 'auto', 'auto'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                <IntlMessage messageId="common.edit" Store={Store} />,
                <IntlMessage messageId="common.delete" Store={Store} />
            ],
            menuroute: [
                '/serviceauftragGrundfos/Detail',
                '/serviceauftragGrundfos/Delete/' + page + '/[ID]'
            ],
            menuParams: ['', 'id', 'id'],
            menuIcons: ['pencil', 'Delete']
        }
    }
    if (response.length > 0) {
        return (
            <>
                <SearchJSX
                    setSearchValue={setSearchValue}
                    Store={Store}
                    SearchConfig={SearchConfig}
                />
                <TableMaker Configuration={Configuration} />
                <PaginationJSX
                    response={response}
                    history={history}
                    to={'overview'}
                    maxPage={limit}
                    handleSuccess={handleSucces}
                    destination={destination}
                />
                {/*{
                    <ContentSwitch
                        pagenew={'new'}
                        destinationnew={'Calender'}
                        tonew={'new'}
                        text={
                            <IntlMessage
                                Store={Store}
                                locale={'de'}
                                messageId={'omment.event.new'}
                            />
                        }
                        response={response}
                        CardsConfiguration={CardsConfiguration}
                        mode={mode}
                        Configuration={Configuration}
                        setMode={setMode}
                        destination={destination}
                        page={page}
                        to={'overview'}
                        setSearchValues={setSearchValue}
                        setSearchs={setSearch}
                        search={false}
                        modalShow={modalShow}
                        setModalShow={setModalShow}
                        modalData={modalData}
                        setModalData={setModalData}
                        SearchParam={'?translation.title'}
                        searchfield={false}
                        allTaxon={[]}
                        _handleKeyFilters={[]}
                        _handleEnter={[]}
                        filter={true}
                        stop={false}
                        Store={Store}
                    />
                }*/}
            </>
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default PageOverview
