import {IntlMessageValue} from "../../../../@WUM/core/component/const/IntlMessage";

const ski = (conf: string,
                   response: any,
                   newElement: any = false,
                   Store: any,
             setcurrenttab:any) => {
    return [
        {
            formType: 'header',
            value: IntlMessageValue('de', 'common.ski.tab.header', Store)
        },
        {
            formType: 'buttonTabeMaker',
            next:'showcheck',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.ski.tab.button.showcheck', Store),
            withBr:true,
            class:'mb-3'
        },
        {
            formType: 'buttonTabeMaker',
            next:'rpe',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.ski.tab.button.rpe', Store),
            withBr:true,
            class:'mb-5'
        },
        {
            formType: 'multiply',
            class:'mb-5',
            cols: [
                {
                    formType: 'buttonTabeMaker',
                    next:'Riso',
                    setter:setcurrenttab,
                    label:IntlMessageValue('de', 'common.ski.tab.button.riso', Store),
                    withBr:true
                },
                {
                    formType: 'buttonTabeMaker',
                    next:'IPE',
                    setter:setcurrenttab,
                    label:IntlMessageValue('de', 'common.ski.tab.button.ipe', Store),
                    withBr:true
                },
                {
                    formType: 'buttonTabeMaker',
                    next:'IEA',
                    setter:setcurrenttab,
                    label:IntlMessageValue('de', 'common.ski.tab.button.iea', Store),
                    withBr:true
                },

            ]
        },
        {
            formType: 'multiply',
            class:'mb-5',
            cols: [
                {
                    formType: 'clear'
                },
                {
                    formType: 'buttonTabeMaker',
                    next:'IBR',
                    setter:setcurrenttab,
                    label:IntlMessageValue('de', 'common.ski.tab.button.ibr', Store),
                    withBr:true
                },
                {
                    formType: 'clear'
                },

            ]
        },

        {
            formType: 'buttonTabeMaker',
            next:'Auswertung',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.ski.tab.button.protocol', Store),
            withBr:true
        }
    ]
}

export default ski;
