import React, { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'
import home from './elements/home'
import { init2 } from '../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { config, output } from './configuration/mapping'
import { error } from '../../../@WUM/core/component/const/error.services'
import { btn_back } from '../../../@WUM/core/component/const/btn_back'
import { handleSubmitForm } from '../../../@WUM/core/component/const/handleSubmitForm'
import { Form } from 'react-bootstrap'
import IntlMessage, {IntlMessageValue} from '../../../@WUM/core/component/const/IntlMessage'
import { ResponseCalenderDetail } from '../API/response/calender/calender'
import { calenderApiDetail } from '../API/request/calender/calenderApiDetail.interface'
import { calender } from '../API/request/calender/calender'
import { useSelector } from 'react-redux'
import { SpinnerJSX } from '../../../@WUM/core/component/const/Spinner'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import {secure} from "../../serviceauftrag/Pages/configuration/home";
import {UniModalFooter} from "../../../@WUM/core/Elements/Modal/Elements/UniModalFooter";
import {UniModalBody} from "../../../@WUM/core/Elements/Modal/Elements/UniModalBody";
import {UniModalHeader} from "../../../@WUM/core/Elements/Modal/Elements/UniModalHeader";
import UniModal from "../../../@WUM/core/Elements/Modal";
import InjectNewElement from "../../../@WUM/core/Elements/InjectNewElement";
import {notify_save_intvall_reload} from "../../../@WUM/core/component/const/notification.service";
const PageDetail = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const { id } = useParams<{ id: string }>()
    const response2 = ResponseCalenderDetail(id)
    const [x, setx] = useState(0)
    const history = useNavigate()

    const [currentTutorial, setCurrentTutorial] =
        useState<calenderApiDetail[]>(response2)

    const [validated, setValidated] = useState(false)

    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     * @param form
     */
    const updateData = (status: boolean, id: any, form: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form)

        console.log(Data)

        let reauestData: calenderApiDetail[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        calender
            .updatePost(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save_intvall_reload(Store)
                $('#btn_save')[0].setAttribute('disabled', 'disbaled')
                setTimeout(() => window.location.reload(), 3000)
            })
            .catch((e) => {
                error(e)
            })
    }
    if (response2.length > 0) {
    return (
        <div>
            <Form
                id={'form001'}
                className={'eventsCalender'}
                noValidate
                validated={validated}
                onSubmit={(e) =>
                    handleSubmitForm(e, updateData, setValidated, id)
                }
            >
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="home"
                        title={'Anschrift'}
                        key={'allgemein'}
                        id={'allgemein'}
                        style={{
                            backgroundColor: '#EFEFEF',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {home((response2.length !== undefined ? response2[0] : response2), Store, x, setx)}
                        <div id={'injectModal'} />
                    </Tab>
                    {/*<Tab eventKey="remind"
                         title={'Belege'}
                         key={'Belege'}
                         id={'Belege'}
                         style={{
                             backgroundColor: '#EFEFEF',
                             padding: '15px',
                             marginTop: '-15px'
                         }}>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>St</th>
                                <th>Title</th>
                                <th>Projekt</th>
                                <th>Nummer</th>
                                <th>Datum</th>
                                <th>Vorhaben</th>
                                <th>Betreff</th>
                                <th>Netto</th>
                                <th>MwSt.</th>
                                <th>Brutto</th>
                                <th>Währ.</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td></td>
                                <td>Angebot</td>
                                <td>TAGLOHN</td>
                                <td>20230704</td>
                                <td>27.10.23</td>
                                <td>KLÄRANLAGE</td>
                                <td>Austausch Motor</td>
                                <td>2.477,77</td>
                                <td>470,78</td>
                                <td>2.948,55</td>
                                <td>EUR</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Angebot</td>
                                <td>TAGLOHN</td>
                                <td>20230676</td>
                                <td>23.10.23</td>
                                <td>T160000</td>
                                <td>RÜB Ringwiese</td>
                                <td>1.700,74</td>
                                <td>323,14</td>
                                <td>2.023,88</td>
                                <td>EUR</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Tab>*/}
                    {localStorage.getItem('userRole') !== 'engineer' && localStorage.getItem('userRole') !== 'locksmith' ?
                    <Tab eventKey="setting"
                         title={'Eigenschaften'}
                         key={'Eigenschaften'}
                         id={'Eigenschaften'}
                         style={{
                             backgroundColor: '#EFEFEF',
                             padding: '15px',
                             marginTop: '-15px'
                         }}>
                        <Row>
                            <Col>
                                <Card className={'p-3'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">Suchen</InputGroup.Text>
                                        <Form.Control
                                            defaultValue={secure(response2[0].SUCHEN,'')}
                                            name={'SUCHEN'}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">Briefanrede</InputGroup.Text>
                                        <Form.Control
                                            defaultValue={secure(response2[0].BrAnrede,'') }
                                            name={'BrAnrede'}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">z.H. Anrede</InputGroup.Text>
                                        <Form.Control
                                            defaultValue={secure(response2[0].zuHaenden,'') }
                                            name={'zuHaenden'}
                                        />
                                    </InputGroup>
                                    <Row>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">Postfach</InputGroup.Text>
                                                <Form.Control
                                                   defaultValue={secure(response2[0].Psf,'') }
                                                   name={'Psf'}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">PLZ</InputGroup.Text>
                                                <Form.Control
                                                    defaultValue={secure(response2[0].Plz,'') }
                                                    name={'Plz'}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                </Card>
                            </Col>
                            <Col>
                                <Card  className={'p-3'}>
                                    <Row>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">Selection</InputGroup.Text>
                                                <Form.Control
                                                    defaultValue={secure(response2[0].Selection1,'') }
                                                    name={'Selection1'}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    defaultValue={secure(response2[0].Selection2,'') }
                                                    name={'Selection2'}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">Branche</InputGroup.Text>
                                        <Form.Control
                                            name={'Branche'}
                                            aria-describedby="basic-addon1"
                                            defaultValue={secure(response2[0].Branche,'') }
                                        />
                                    </InputGroup>
                                    <Row>
                                        <Col className={'col-8'}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">Einstufung</InputGroup.Text>
                                                <Form.Select aria-label="Default select example">
                                                    <option label={'Bitte Einstufung wählen'}></option>
                                                    <option value="kein Status">kein Status</option>
                                                    <option value="Sonstiges">- Sonstiges</option>
                                                    <option value="A – Interessent">A – Interessent</option>
                                                    <option value="B – Interessent">B – Interessent</option>
                                                    <option value="C – Interessent">C – Interessent</option>
                                                    <option value="Unwichtig">Unwichtig</option>
                                                    <option value="Wartung">Wartung</option>
                                                    <option value="Stammkunde">Stammkunde</option>
                                                    <option value="Kunde">Kunde</option>
                                                    <option value="Zahlt nicht">Zahlt nicht</option>
                                                    <option value="Gericht">Gericht</option>
                                                    <option value="Titel/Konkurs">Titel/Konkurs</option>
                                                    <option value="Privat">Privat</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">Achtung</InputGroup.Text>
                                                <Form.Select aria-label="Default select example" name={'SpKzJn'} defaultValue={secure(response2[0].SpKzJn,'')}>
                                                    <option value="0">Nein</option>
                                                    <option value="1">Ja</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Col>
                                       {/* <Col>
                                            <Form.Check
                                                type={'checkbox'}
                                                label={`Achtung`}
                                                name={'SpKzJn'}
                                                defaultValue={secure(response2[0].SpKzJn,'')}
                                            />
                                        </Col>*/}
                                    </Row>
                                    <Row>
                                        <Col className={'col-8'}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">Ust.IDent</InputGroup.Text>
                                                <Form.Control
                                                    defaultValue={secure(response2[0].UstId1,'') }
                                                    name={'UstId1'}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">§13b</InputGroup.Text>
                                                <Form.Select aria-label="Default select example" name={'KzPreisPfl'} defaultValue={secure(response2[0].KzPreisPfl,'')}>
                                                    <option value="0">Nein</option>
                                                    <option value="1">Ja</option>
                                                </Form.Select>
                                            </InputGroup>
                                            {/*<Form.Check // prettier-ignore
                                                type={'checkbox'}
                                                id={`default-`}
                                                label={`§13b `}
                                                name={'KzPreisPfl'}
                                                defaultValue={secure(response2[0].KzPreisPfl,'')}
                                            />*/}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card  className={'mt-3 p-3'}>
                                    <Form.Control
                                        type={'hidden'}
                                        defaultValue={response2[0].Bemerkungen !== undefined && response2[0].Bemerkungen !== null ? secure(response2[0].Bemerkungen.AdrNr,'') : secure(response2[0].DESTDEBNR,'') }
                                        name={'Bemerkungen[AdrNr]'}
                                    />
                                    <Form.Control
                                        type={'hidden'}
                                        defaultValue={response2[0].Bemerkungen !== undefined && response2[0].Bemerkungen !== null ? secure(response2[0].Bemerkungen.RecId,'') : '0'}
                                        name={'Bemerkungen[RecId]'}
                                    />
                                    <Form.Control
                                        type={'hidden'}
                                        defaultValue={response2[0].Bemerkungen !== undefined && response2[0].Bemerkungen !== null ? secure(response2[0].Bemerkungen.id,'') : '0'}
                                        name={'Bemerkungen[id]'}
                                    />
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Bemerkung</Form.Label>
                                    <Form.Control  name={'Bemerkungen[Bemerkung]'} as="textarea" rows={3} defaultValue={response2[0].Bemerkungen !== undefined && response2[0].Bemerkungen !== null ? secure(response2[0].Bemerkungen.Bemerkung,'') : ''} />
                                </Form.Group>
                            </Card>
                            </Col>
                        </Row>
                    </Tab>
                        :
                        <>
                        </>
                    }
                </Tabs>
                <div style={{ backgroundColor: '#EFEFEF', padding: '15px' }}>
                    <Row className={"justify-content-between"}>
                        <Col sm={1}>
                            {btn_back(history, 'common.back', '', Store)}
                        </Col>
                        <Col sm={3} className={'text-center'}>
                            <UniModal Button={{Text:'Ansprechpartner/Einsatzadresse hinzufügen',Class:' mr-3',Type:'button'}} >
                                <UniModalHeader elements={'Ansprechpartner/Einsatzadresse hinzufügen'} />
                                <UniModalBody elements={
                                    <>
                                        <p>Bitte auswählen</p>
                                        <Button variant={'success'} className={'float-start'} onClick={()=>
                                            InjectNewElement({index:x,SetIndex:setx,injectElementId:'injectModal',elements: <>
                                                    <h3 className={'text-center'}>Ansprechpartner</h3>
                                                    <Row className={'multiborderComplete row'}>

                                                        <Col>
                                                            <Form.Control type="hidden" name={'KdData[' + x + '][id]'}
                                                                          defaultValue={'0'}
                                                                          placeholder={IntlMessageValue('de', 'common.member.name', Store)}/>
                                                            <Form.Group className="mb-3"
                                                                        controlId="exampleForm.ControlInput1">
                                                                <Form.Label>{IntlMessageValue('de', 'customer.table.name', Store)}</Form.Label>
                                                                <Form.Control type="text"
                                                                              name={'KdData[' + x + '][name]'}
                                                                              placeholder={IntlMessageValue('de', 'common.member.name', Store)}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3"
                                                                        controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Telefon</Form.Label>
                                                                <Form.Control type="text"
                                                                              name={'KdData[' + x + '][phone]'}
                                                                              placeholder={'Bitte Telefonnummer eingeben'}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3"
                                                                        controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Mobil (Ansprechpartner)</Form.Label>
                                                                <Form.Control type="text"
                                                                              name={'KdData[' + x + '][area]]'}
                                                                              placeholder={'Bitte Mobil (Ansprechpartner) eingeben'}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3"
                                                                        controlId="exampleForm.ControlInput1">
                                                                <Form.Label>E-Mail</Form.Label>
                                                                <Form.Control type="text"
                                                                              name={'KdData[' + x + '][email]'}
                                                                              placeholder={IntlMessageValue('de', 'common.member.email', Store)}/>
                                                            </Form.Group>
                                                            <Form.Control type="hidden" name={'KdData[' + x + '][asp]'}
                                                                          defaultValue={'1'}
                                                                          placeholder={IntlMessageValue('de', 'common.member.email', Store)}/>

                                                        </Col>
                                                    </Row>
                                                </>
                                            })}>
                                        Ansprechpartner
                                        </Button>

                                        <Button variant={'success'}  className={'float-end'} onClick={()=>
                                            InjectNewElement({index:x,SetIndex:setx,injectElementId:'injectModal',elements: <>
                                                    <h3 className={'text-center'}>Einsatzadresse</h3>
                                                    <Row className={'multiborderTop row'}>

                                                        <Col>
                                                            <Form.Control type="hidden" name={'KdData[' + x + '][id]'}
                                                                          defaultValue={'0'}
                                                                          placeholder={IntlMessageValue('de', 'common.member.name', Store)}/>

                                                            <Form.Group className="mb-3"
                                                                        controlId="exampleForm.ControlInput1">
                                                                <Form.Label>{IntlMessageValue('de', 'customer.table.name', Store)}</Form.Label>
                                                                <Form.Control type="text"
                                                                              name={'KdData[' + x + '][name]'}
                                                                              placeholder={IntlMessageValue('de', 'common.member.name', Store)}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3"
                                                                        controlId="exampleForm.ControlInput1">
                                                                <Form.Label>{IntlMessageValue('de', 'customer.table.street', Store)}</Form.Label>
                                                                <Form.Control type="text"
                                                                              name={'KdData[' + x + '][street]'}
                                                                              placeholder={IntlMessageValue('de', 'common.member.street', Store)}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3"
                                                                        controlId="exampleForm.ControlInput1">
                                                                <Form.Label>{'PLZ/' + IntlMessageValue('de', 'customer.table.city', Store)}</Form.Label>
                                                                <Form.Control type="text"
                                                                              name={'KdData[' + x + '][city]'}
                                                                              placeholder={IntlMessageValue('de', 'common.member.city', Store)}/>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className={'multiborderBottom row'}>
                                                        <Col>
                                                            <Form.Group className="mb-3"
                                                                        controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Telefon</Form.Label>
                                                                <Form.Control type="text"
                                                                              name={'KdData[' + x + '][phone]'}
                                                                              placeholder={'Bitte Telefonnummer eingeben'}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3"
                                                                        controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Ansprechpartner (Standort)</Form.Label>
                                                                <Form.Control type="text"
                                                                              name={'KdData[' + x + '][area]]'}
                                                                              placeholder={IntlMessageValue('de', 'common.contact_person_location', Store)}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3"
                                                                        controlId="exampleForm.ControlInput1">
                                                                <Form.Label>E-Mail</Form.Label>
                                                                <Form.Control type="text"
                                                                              name={'KdData[' + x + '][email]'}
                                                                              placeholder={IntlMessageValue('de', 'common.member.email', Store)}/>
                                                            </Form.Group>
                                                            <Form.Control type="hidden" name={'KdData[' + x + '][asp]'}
                                                                          defaultValue={'0'}
                                                                          placeholder={IntlMessageValue('de', 'common.member.email', Store)}/>
                                                        </Col>
                                                    </Row>
                                                </>
                                            })}>
                                        Einsatzadresse
                                        </Button>
                                    </>
                                } />
                                <UniModalFooter elements={''} />
                            </UniModal>
                        </Col>
                        <Col sm={1}>
                            <Button
                                variant="success"
                                id={'btn_save'}
                                style={{ float: 'right', marginRight: '15px' }}
                                type={'submit'}
                            >
                                <IntlMessage messageId="common.save" Store={Store} />
                            </Button>
                        </Col>

                    </Row>


                    <br style={{ clear: 'both' }} />
                </div>
            </Form>
        </div>
    )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default PageDetail
