import de from './Language/de_DE.json'
import deTicketsystemMessages from '../modules/ticketsystem/modules/localization/locales/de_DE.json'

import en from './Language/en_EN.json'
import enTicketsystemMessages from '../modules/ticketsystem/modules/localization/locales/en_US.json'
export const RegisterLanguageDE = {
    ...de,
    ...deTicketsystemMessages
}

export const RegisterLanguageEN = {
    ...en,
    ...enTicketsystemMessages
}

const deLang = {
    messages: {
        ...RegisterLanguageDE
    },
    locale: 'de'
}
const EnLang = {
    messages: {
        ...RegisterLanguageEN
    },
    locale: 'us'
}

export const AppLocale: any = {
    de: deLang,
    us: EnLang
}
