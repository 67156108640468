import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {


    const titel = (
        <>

        </>
    )

    const description = (
        <>

        </>
    )

    const dataColOne = [
        {
            formType: 'input',
            type: 'hidden',
            name: 'id',
            value: newElement === false ? secure(response.id,'0') : '0'
        },
        {
            formType: 'input',
            label: 'KdNr',
            required: true,
            type: 'text',
            placeholder: 'Bitte KdNr eingeben',
            name: 'KdNr',
            value: newElement === false ? secure(response.KdNr,'') : ''
        },
        {
            formType: 'input',
            label: 'Name',
            required: true,
            type: 'text',
            placeholder: 'Bitte Name eingeben',
            name: 'Name',
            value: newElement === false ? secure(response.Name,'') : ''
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: 'EMail',
                    required: true,
                    type: 'text',
                    placeholder: 'EMail',
                    name: 'EMail',
                    value: newElement === false ? secure(response.EMail,'') : ''
                },
                {
                    formType: 'input',
                    label: 'Ort',
                    required: true,
                    type: 'text',
                    placeholder: 'Ort',
                    name: 'Ort',
                    value: newElement === false ? secure(response.Ort,'') : ''
                },{
                    formType: 'input',
                    label: 'Strasse',
                    required: true,
                    type: 'text',
                    placeholder: 'Strasse',
                    name: 'Strasse',
                    value: newElement === false ? secure(response.Strasse,'') : ''
                },
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: 'Telefax',
                    type: 'text',
                    placeholder: 'Bitte Telefax eingeben',
                    name: 'Telefax',
                    value: newElement === false ? secure(response.Telefax,'') : ''
                },
                {
                    formType: 'input',
                    label: 'Telefon1',
                    type: 'text',
                    placeholder: 'Bitte Telefon1 eingeben',
                    name: 'Telefon1',
                    value: newElement === false ? secure(response.Telefon1,'') : ''
                },
                {
                    formType: 'input',
                    label: 'Telefon2',
                    type: 'text',
                    placeholder: 'Bitte Telefon2 eingeben',
                    name: 'Telefon2',
                    value: newElement === false ? secure(response.Telefon2,'') : ''
                }
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: 'Sachbearbeiter1',
                    type: 'text',
                    placeholder: 'Bitte Sachbearbeiter1 eingeben',
                    name: 'Sachbearbeiter1',
                    value: newElement === false ? secure(response.Sachbearbeiter1,'') : ''
                },
                {
                    formType: 'input',
                    label: 'Sachbearbeiter2',
                    type: 'text',
                    placeholder: 'Bitte Sachbearbeiter2 eingeben',
                    name: 'Sachbearbeiter2',
                    value: newElement === false ? secure(response.Sachbearbeiter2,'') : ''
                },
                {
                    formType: 'input',
                    label: 'melden_bei',
                    type: 'text',
                    placeholder: 'Bitte melden_bei eingeben',
                    name: 'melden_bei',
                    value: newElement === false ? secure(response.melden_bei,'') : ''
                }
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: 'Status_Gelb',
                    type: 'text',
                    placeholder: 'Bitte Status_Gelb eingeben',
                    name: 'Status_Gelb',
                    value: newElement === false ? secure(response.Status_Gelb,'') : ''
                },
                {
                    formType: 'input',
                    label: 'Status_gruen',
                    type: 'text',
                    placeholder: 'Bitte Status_gruen eingeben',
                    name: 'Status_gruen',
                    value: newElement === false ? secure(response.Status_gruen,'') : ''
                }
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: 'AG',
                    type: 'text',
                    placeholder: 'Bitte AG eingeben',
                    name: 'AG',
                    value: newElement === false ? secure(response.AG,'') : ''
                },
                {
                    formType: 'input',
                    label: 'KD',
                    type: 'text',
                    placeholder: 'Bitte KD eingeben',
                    name: 'KD',
                    value: newElement === false ? secure(response.KD,'') : ''
                }
            ]
        },
    ]

    if (conf === 'titel') {
        return titel
    } else if (conf === 'description') {
        return description
    } else if (conf === 'dataColOne') {
        return dataColOne
    } else if (conf === 'dataColtwo') {
        return;
    }
}

export default configuration


export const secure = (param:any,alternate:any,debug?:any,first?:any) => {

    if(first !== undefined && first !== null) {
        if (param !== undefined && param !== null && param !== '' && param !== 'undefined') {
            if (debug !== undefined) {
                console.log(param)
            }


            return param
        } else {
            return alternate;
        }
    } else {
        if (param !== undefined && param !== null && param !== '' && param !== 'undefined') {
            if (debug !== undefined) {
                console.log(param)
            }


            return param
        } else {
            return alternate;
        }
    }

}
