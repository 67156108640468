import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import {secure} from '../configuration/home'
import IntlMessage, {IntlMessageValue} from '../../../../@WUM/core/component/const/IntlMessage'
import {isEmptyArray} from 'formik'
import dateTimeHelper from "../../../../helper/dateTimeHelper";
import HTMLFetchSelect from "../../../../@WUM/core/component/const/HTMLFetchSelect";
import DataList, {
    DataListSingle,
    DataListSingleNoneLabel
} from "../../../../@WUM/core/component/const/DataList";
import ReactDOM from "react-dom";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {colorCheck} from "../../../../@WUM/core/component/const/ColorCheck";

function home(response: any, Store: any,setKdId?:any) {

    if (response === 'new') {
        return (
            <>
                {form(response,Store,true,setKdId)}
            </>
        )
    } else if (isEmptyArray(response) === false) {
        return (
            <>
                {form(response,Store,false,setKdId)}
            </>
        )
    } else {
        return (
            <>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                        <IntlMessage messageId="common.loading" Store={Store}/>
                    </span>
                </Spinner>
            </>
        )
    }
}

export default home


export const form = (response:any,Store:any,newElement:any,setKdId:any ) => {

    const ColorStatus = (Name:any) => {
        let ColorMap = [
            {name: '', color:'#000000'},
            {name: '0 Erfasst', color: '#FF0000'},
            {name: '1 Geplant', color: '#F44336'},
            {name: '2 Bericht erstellt', color: '#FF5722'},
            {name: '4 Angebot erstellt', color: '#FFEB3B'},
            {name: '5 Auftrag erteilt', color: '#CDDC39'},
            {name: '6 Teile bestellt', color: '#FF9800'},
            {name: '7 Reparatur erledigt', color: '#8BC34A'},
            {name: '8 Kunden informiert', color: '#4CAF50'},
            {name: '9 Geschlossen', color: '#00FF00'},
            {name: '10 Termin geplant', color: '#FF9800'},
            {name: '11 Bericht erstellt', color: '#8BC34A'},
        ]

        let Color = '';

        for (let c = 0; c < ColorMap.length; c++) {
            if (Name === ColorMap[c].name) {
                Color = ColorMap[c].color
            }
        }

        return Color;
    }

    const changeColor = (e:any) => {

        let Color = ColorStatus(e.target.value);
        let Field = document.getElementById('StatusColor') as HTMLInputElement;
        let SelectField = document.getElementById(e.target.id) as HTMLSelectElement;
        if(Field !== null) {
            Field.value = Color;
            SelectField.style.background = Color
            SelectField.style.color = (Color !== undefined && colorCheck(Color,false) === false ? 'white' : 'black')
        }

    }

    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col>
                        <Form.Select name={'AUFTRAGSAR'} defaultValue={newElement === false ? secure(response.AUFTRAGSAR,'') : ''}
                                     onChange={(e) => switcher(e.target.value,Store)}
                                     disabled={newElement === false ? true : false} aria-label="Default select example">
                            <option>Bitte Auftragsart wählen</option>
                            <option value="Serviceauftrag">Serviceauftrag</option>
                            <option value="Prüfbericht">Prüfbericht</option>
                        </Form.Select>
                        </Col>
                        <Col>
                                <DataListSingleNoneLabel id={'test10'}
                                                Store={Store}
                                                response={newElement === false ? secure(response.SERVICEBER,'') : ''}
                                                name={'SERVICEBER'} label={''}
                                                idInput={'test41'} fetch={JSON.parse(localStorage.getItem('AllUSerSaSB') as string)}
                                                value1={'first_name'} value2={'last_name'}  />
                        </Col>
                        <Col>
                            <input name="AUFTRAGNR"
                                   autoComplete="on"
                                   placeholder={'Auftrg.Nr. automatisch generiert'}
                                   type="text"
                                   readOnly={true}
                                   id="formFile1KdNr"
                                   className="form-control"
                                   defaultValue={ newElement === false ? secure(response.AUFTRAGNR,'') : ''  }/>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Row>
                                <Col sm={4}>
                                    <label className="form-label" htmlFor="formFile1KdNr">
                                        <IntlMessage Store={Store} messageId="common.sa.meeting" />:
                                    </label>
                                </Col>
                                <Col sm={8}>
                                    <input name="DATUM"
                                           autoComplete="on"
                                           placeholder={IntlMessageValue('de', 'common.sa.meeting.provide', Store)}
                                           type="date"
                                           id="formFile1KdNr"
                                           className="form-control"
                                           defaultValue={ newElement === false ? (response.DATUM !== null ? dateTimeHelper( response.DATUM,
                                                   "YYYY-m-d"  ,
                                                   "M.D.YY",
                                                   ".") : '')
                                               : ''}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <label className="form-label" htmlFor="formFile1KdNr">
                                        <IntlMessage Store={Store} messageId="stock.keeping" />:
                                    </label>
                                </Col>
                                <Col>
                                    <Form.Select aria-label="Default select example" name={'Lagerplatz'} defaultValue={newElement === false ? secure(response.Lagerplatz,'') : ''}>
                                        <option></option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Col>
            </Row>

            <Row>
                <Col><Row>
                    <Col>
                      <DataList id={'test1'}
                                Store={Store} defaultValue={response.AG_KDNR}
                                  name={'AG_KDNR'} label={IntlMessageValue('de', 'common.sa.client', Store)} idInput={'test2'} fetch={Store.Agd.objeckt}
                                  value1={'KdNr'} value2={'Name'} onChange={setKdId} clear={['test4','kd_adress','kd_please_contact','kd_tel','kd_tel2','kd_mail']}  idSet={[
                                      {type:'textarea',id:'agd_adress',key:false, keyMap:['Name','Strasse','Ort']},
                                      {type:'input',id:'agd_name',key:'Name'},
                                      {type:'input',id:'agd_tel',key:'Telefon1'},
                                      {type:'input',id:'agd_fax',key:'Telefon2'},
                                      {type:'input',id:'agd_mail',key:'EMail'}]}  />
                    </Col>

                </Row></Col>
                <Col><Row>
                  <Col>
                        <DataList id={'test3'} Store={Store}  defaultValue={response.KD_KDNR}
                                  name={'KD_KDNR'} label={'Einsatzadresse'} idInput={'test4'} fetch={Store.Kd.objeckt}
                                  value1={'KdNr'} value2={'Name'} idSet={[
                            {type:'textarea',id:'kd_adress',key:false, keyMap:['Name','Strasse','Ort']},
                            {type:'input',id:'kd_please_contact',key:'melden_bei'},
                            {type:'input',id:'kd_tel',key:'Telefon1'},
                            {type:'input',id:'kd_tel2',key:'Telefon2'},
                            {type:'input',id:'kd_mail',key:'EMail'}]}/>
                    </Col>
                </Row></Col>
            </Row>
            <Row>
                <Col>
                    <textarea name="translations[description]"
                              style={{minHeight: 90}}  readOnly={true}
                              id={'agd_adress'} className="form-control"
                              data-lt-tmp-id="lt-921884" spellCheck="false" data-gramm="false">{
                        newElement === false && response.ag !== null && response.ag !== undefined ?
                            secure(response.ag.Name,'') + "\r\n" + secure(response.ag.Strasse,'') + "\r\n" + secure(response.ag.Ort,'')

                   : '' }</textarea>
                </Col>
                <Col>
                    <textarea name="translations[description]"
                              style={{minHeight: 90}} readOnly={true}
                              id="kd_adress" className="form-control"
                              data-lt-tmp-id="lt-921884" spellCheck="false" data-gramm="false">{
                        newElement === false && response.kd !== null && response.kd !== undefined ?
                            secure(response.kd.Name,'') + "\r\n" + secure(response.kd.Strasse,'') + "\r\n" + secure(response.kd.Ort,'')
                    :''
                    }</textarea>
                </Col>
            </Row>
            <Row>
                <Col> <br />
                    <Row >

                        <Col sm={4}>
                            <label className="form-label" htmlFor="formFile1KdNr">
                                <IntlMessage Store={Store} messageId="common.sa.common.Client" />:</label>
                        </Col>
                        <Col sm={8}>
                            <input name="agd_name"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.common.Client.provide', Store)}
                                   type="text"
                                   id={'agd_name'}
                                   className="form-control"
                                   defaultValue={newElement === false && response.ag !== null && response.ag !== undefined ? secure(response.ag.Name,'') : ''}/>
                        </Col>

                    </Row>

                    <Row >
                        <Col sm={4}>
                            <label className="form-label" htmlFor="formFile1KdNr">
                                <IntlMessage Store={Store} messageId="common.sa.tel" />:
                            </label>
                        </Col>
                        <Col sm={8}>
                            <input name="agd_tel1"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.tel.provide', Store)}
                                   type="text"
                                   id={'agd_tel'}
                                   className="form-control"
                                   defaultValue={newElement === false && response.ag !== null && response.ag !== undefined ? secure(response.ag.Telefon1,'') : ''}/>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={4}>
                            <label className="form-label" htmlFor="formFile1KdNr">
                                <IntlMessage Store={Store} messageId="common.sa.fax" />:
                            </label>
                        </Col>
                        <Col sm={8}>
                            <input name="agd_fax"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.fax.provide', Store)}
                                   type="text"
                                   id='agd_fax'
                                   className="form-control"
                                   defaultValue={newElement === false && response.ag !== null && response.ag !== undefined ? secure(response.ag.Telefax,'') : ''}/>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={4}>
                            <label className="form-label" htmlFor="formFile1KdNr">
                                <IntlMessage Store={Store} messageId="common.sa.mail" />:
                            </label>
                        </Col>
                        <Col sm={8}>
                            <input name="agd_mail"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.mail.provide', Store)}
                                   type="text"
                                   id='agd_mail'
                                   className="form-control"
                                   defaultValue={newElement === false && response.ag !== null && response.ag !== undefined ? secure(response.ag.EMail,'') : ''}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <label className="form-label" htmlFor="formFile1KdNr">
                                <IntlMessage Store={Store} messageId="common.sa.customer_order" />:
                            </label>
                        </Col>
                        <Col sm={8}>
                           {/* <input name="IHRE_BESTE"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.customer_order.provide', Store)}
                                   type="text"
                                   id="formFile1KdNr"
                                   className="form-control"
                                   defaultValue={newElement === false ? secure(response.IHRE_BESTE,'') : ''}/>*/}
                            <Form.Control as="textarea" name="IHRE_BESTE" rows={4} maxLength={1300}
                                          placeholder={IntlMessageValue('de', 'common.sa.customer_order.provide', Store)}
                                          defaultValue={newElement === false ? secure(response.IHRE_BESTE,'') : ''}
                            />
                        </Col>
                    </Row>
                  {/*  <Row>
                        <Col sm={4}>
                            <label className="form-label" htmlFor="formFile1KdNr">
                                <IntlMessage Store={Store} messageId="common.sa.customer_order_date" />:
                            </label>
                        </Col>
                        <Col sm={8}>
                            <input name="TERMIN"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.customer_order_date.provide', Store)}
                                   type="text"
                                   id="formFile1KdNr"
                                   className="form-control"
                                   defaultValue={newElement === false ? secure(response.TERMIN,'') : ''}/>
                                <Form.Control as="textarea" name="TERMIN" rows={3} defaultValue={newElement === false ? secure(response.TERMIN,'') : ''}/>

                        </Col>
                    </Row>*/}
                </Col>
                <Col><br/>
                    <Row>
                        <Col sm={4}>
                            <label className="form-label" htmlFor="formFile1KdNr">
                                <IntlMessage Store={Store} messageId="common.sa.contact_on_site"/>:
                            </label>
                        </Col>
                        <Col sm={8}>
                            <input name="kd_please_contact"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.contact_on_site.provide', Store)}
                                   type="text"
                                   id="kd_please_contact"
                                   className="form-control"
                                   defaultValue={newElement === false && response.kd !== null && response.kd !== undefined && response.kd.melden_bei !== undefined ? secure(response.kd.melden_bei,'') : ''}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <label className="form-label" htmlFor="formFile1KdNr">
                                <IntlMessage Store={Store} messageId="common.sa.tel" />:
                            </label>
                        </Col>
                        <Col sm={8}>
                            <input name="kd_tel"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.tel.provide', Store)}
                                   type="text"
                                   id="kd_tel"
                                   className="form-control"
                                   defaultValue={newElement === false && response.kd !== null && response.kd !== undefined ? secure(response.kd.Telefon1,'') : ''}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <label className="form-label" htmlFor="formFile1KdNr">
                                <IntlMessage Store={Store} messageId="common.sa.mobil" />:
                            </label>
                        </Col>
                        <Col sm={8}>
                            <input name="kd_tel2"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.mobil.provide', Store)}
                                   type="text"
                                   id="kd_tel2"
                                   className="form-control"
                                   defaultValue={newElement === false && response.kd !== null && response.kd !== undefined ? secure(response.kd.Telefon2,'') : ''}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <label className="form-label" htmlFor="formFile1KdNr">
                                <IntlMessage Store={Store} messageId="common.sa.mail" />:
                            </label>
                        </Col>
                        <Col sm={8}>
                            <input name="kd_mail"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.mail.provide', Store)}
                                   type="text"
                                   id="kd_mail"
                                   className="form-control"
                                   defaultValue={newElement === false && response.kd !== null && response.kd !== undefined ? secure(response.kd.EMail,'') : ''}/>
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>

                        <Col sm={4}>Status</Col>
                        <Col sm={1}> <Form.Control
                            type="color"
                            id="StatusColor"
                            title="Choose your color"
                            disabled={true}
                            defaultValue={(newElement === false ? ColorStatus(response.STATUS) : '')}
                        /></Col>
                        <Col sm={7}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >

                                <Form.Select aria-label="Default select example" id={'pruefbericht_status'} name={'STATUS'}
                                             onChange={(e)=>changeColor(e)}
                                             defaultValue={(newElement === false ? response.STATUS : '')} style={{width: '100%',background:ColorStatus((newElement === false ?response.STATUS : '')),color:(ColorStatus((newElement === false ? response.STATUS:undefined)) !== undefined && colorCheck(ColorStatus((newElement === false ? response.STATUS : '')),false) === false ? 'white' : 'black'),
                                    display: (newElement === false ? (response.AUFTRAGSAR === 'Prüfbericht' ? 'block' : 'none') : 'block')}}>
                                    <option label={'Bitte Wählen'} style={{background:ColorStatus(""),color:(ColorStatus("") !== undefined && colorCheck(ColorStatus(""),false) === false ? 'white' : 'black')}} />
                                    <option value="0 Erfasst" style={{background:ColorStatus("0 Erfasst"),color:(ColorStatus("0 Erfasst") !== undefined && colorCheck(ColorStatus("0 Erfasst"),false) === false ? 'white' : 'black')}}>Erfasst</option>
                                    <option value="1 Geplant" style={{background:ColorStatus("1 Geplant"),color:(ColorStatus("1 Geplant") !== undefined && colorCheck(ColorStatus("1 Geplant"),false) === false ? 'white' : 'black')}}>In Bearbeitung / Überprüfung</option>
                                    <option value="2 Bericht erstellt" style={{background:ColorStatus("2 Bericht erstellt"),color:(ColorStatus("2 Bericht erstellt") !== undefined && colorCheck(ColorStatus("2 Bericht erstellt"),false) === false ? 'white' : 'black')}}>Bericht erstellt</option>
                                    <option value="6 Teile bestellt" style={{background:ColorStatus("6 Teile bestellt"),color:(ColorStatus("6 Teile bestellt") !== undefined && colorCheck(ColorStatus("6 Teile bestellt"),false) === false ? 'white' : 'black')}}>Teile angefragt</option>
                                    <option value="4 Angebot erstellt" style={{background:ColorStatus("4 Angebot erstellt"),color:(ColorStatus("4 Angebot erstellt") !== undefined && colorCheck(ColorStatus("4 Angebot erstellt"),false) === false ? 'white' : 'black')}}>Angebot erstellt</option>
                                    <option value="5 Auftrag erteilt" style={{background:ColorStatus("5 Auftrag erteilt"),color:(ColorStatus("5 Auftrag erteilt") !== undefined && colorCheck(ColorStatus("5 Auftrag erteilt"),false) === false ? 'white' : 'black')}}>Auftrag erteilt</option>
                                    <option value="7 Reparatur erledigt" style={{background:ColorStatus("7 Reparatur erledigt"),color:(ColorStatus("7 Reparatur erledigt") !== undefined && colorCheck(ColorStatus("7 Reparatur erledigt"),false) === false ? 'white' : 'black')}}>Reparatur erledigt</option>
                                    <option value="8 Kunden informiert" style={{background:ColorStatus("8 Kunden informiert"),color:(ColorStatus("8 Kunden informiert") !== undefined && colorCheck(ColorStatus("8 Kunden informiert"),false) === false ? 'white' : 'black')}}>Kunde informiert</option>
                                    <option value="9 Geschlossen" style={{background:ColorStatus("9 Geschlossen"),color:(ColorStatus("9 Geschlossen") !== undefined && colorCheck(ColorStatus("9 Geschlossen"),false) === false ? 'white' : 'black')}}>Abgeschlossen und Abgerechnet</option>
                                </Form.Select>

                                <Form.Select aria-label="Default select example" id={'servicebericht_status'} name={'STATUS'}
                                             onChange={(e)=>changeColor(e)}
                                             defaultValue={(newElement === false ? response.STATUS : '')}
                                             style={{width: '100%',background:ColorStatus((newElement === false ?response.STATUS : '')),color:(ColorStatus((newElement === false ? response.STATUS:undefined)) !== undefined && colorCheck(ColorStatus((newElement === false ? response.STATUS : '')),false) === false ? 'white' : 'black'),
                                                 display:(newElement === false ? (response.AUFTRAGSAR === 'Prüfbericht' ? 'none' : 'block'):'none')}}>
                                    <option label={'Bitte Wählen'} style={{background:ColorStatus(""),color:(ColorStatus("") !== undefined && colorCheck(ColorStatus(""),false) === false ? 'white' : 'black')}}/>
                                    <option value="0 Erfasst" style={{background:ColorStatus("0 Erfasst"),color:(ColorStatus("0 Erfasst") !== undefined && colorCheck(ColorStatus("0 Erfasst"),false) === false ? 'white' : 'black')}}>Erfasst </option>
                                    <option value="1 Geplant" style={{background:ColorStatus("1 Geplant"),color:(ColorStatus("1 Geplant") !== undefined && colorCheck(ColorStatus("1 Geplant"),false) === false ? 'white' : 'black')}}>Angebotserstellung</option>
                                    <option value="4 Angebot erstellt" style={{background:ColorStatus("4 Angebot erstellt"),color:(ColorStatus("4 Angebot erstellt") !== undefined && colorCheck(ColorStatus("4 Angebot erstellt"),false) === false ? 'white' : 'black')}}>Angebot erstellt</option>
                                    <option value="5 Auftrag erteilt" style={{background:ColorStatus("5 Auftrag erteilt"),color:(ColorStatus("5 Auftrag erteilt") !== undefined && colorCheck(ColorStatus("5 Auftrag erteilt"),false) === false ? 'white' : 'black')}}>Auftrag erteilt</option>
                                    <option value="10 Termin geplant" style={{background:ColorStatus("10 Termin geplant"),color:(ColorStatus("10 Termin geplant") !== undefined && colorCheck(ColorStatus("10 Termin geplant"),false) === false ? 'white' : 'black')}}>Termin geplant</option>
                                    <option value="11 Bericht erstellt" style={{background:ColorStatus("11 Bericht erstellt"),color:(ColorStatus("11 Bericht erstellt") !== undefined && colorCheck(ColorStatus("11 Bericht erstellt"),false) === false ? 'white' : 'black')}}>Bericht erstellt</option>
                                    <option value="9 Geschlossen" style={{background:ColorStatus("9 Geschlossen"),color:(ColorStatus("9 Geschlossen") !== undefined && colorCheck(ColorStatus("9 Geschlossen"),false) === false ? 'white' : 'black')}}>Abgeschlossen und Abgerechnet</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={4}>
                            <IntlMessage Store={Store} messageId="common.sa.offer" />:
                        </Col>
                        <Col sm={8}>
                            <input name="Angebot"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.offer.provide', Store)}
                                   type="text"
                                   id="Angebot"
                                   className="form-control"
                                   defaultValue={newElement === false ? response.Angebot : ''}/>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={4}>
                            Rechnungs-Nr.:
                        </Col>
                        <Col sm={8}>
                            <input name="billnumber"
                                   autoComplete="on"
                                   placeholder={''}
                                   type="text"
                                   id="billnumber"
                                   className="form-control"
                                   defaultValue={newElement === false ? response.billnumber : ''}/>
                        </Col>
                    </Row>
                    {response.AUFTRAGSAR === 'Prüfbericht' ? <>
                    <Row className={'mt-3'}>
                        <Col sm={4}>Angebot erwünscht</Col>
                        <Col sm={8}>
                            <Form.Select aria-label="Default select example"
                                         name={"proposal"} id={"proposal"}
                                         defaultValue={newElement === false && response.proposal !== null && response.proposal !== undefined ? response.proposal : '1'} >
                                <option value={"0"}>Nein</option>
                                <option value={"1"}>Ja</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        <Col sm={4}>Reparatur gewünscht</Col>
                        <Col sm={8}>
                            <Form.Select name={"needRepair"} id={"needRepair"}
                                         defaultValue={newElement === false && response.needRepair !== null && response.needRepair !== undefined ? response.needRepair : '0'} >
                                <option value={"0"}>Nein</option>
                                <option value={"1"}>Ja</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    </>: <></>
                    }
                    <br />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="FEHLERANGA">
                        <Form.Label><IntlMessage Store={Store} messageId="common.sa.error_description" />:</Form.Label>
                        <Form.Control name="FEHLERANGA" as="textarea" rows={10} maxLength={1055} defaultValue={newElement === false ? secure(response.FEHLERANGA,'') : ''} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>

                        <label className="form-label" htmlFor="PRODUKTNR">
                            <IntlMessage Store={Store} messageId="common.sa.product_number" />:
                        </label>
                        <br/>
                        <Col>
                            <input name="PRODUKTNR"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.product_number.provide', Store)}
                                   type="text"
                                   id="PRODUKTNR"
                                   className="form-control"
                                   defaultValue={newElement === false ? secure(response.PRODUKTNR,'') : ''}/>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>

                        <label className="form-label" htmlFor="PRODUKT">
                            <IntlMessage Store={Store} messageId="common.sa.product_description" />:
                        </label>
                        <br/>
                        <Col>
                            <input name="PRODUKT"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.product_description.provide', Store)}
                                   type="text"
                                   id="PRODUKT"
                                   className="form-control"
                                   defaultValue={newElement === false ? secure(response.PRODUKT,'') : ''}/>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <DataListSingle id={'ANWENDUNG1'}
                                    Store={Store} response={newElement === false ? response.ANWENDUNG : ''}
                                    name={'ANWENDUNG'} label={IntlMessageValue('de', 'common.sa.application', Store)}
                                    idInput={'ANWENDUNG'} fetch={Store.Application.objeckt}
                                    value1={'code'} value2={'content'} labelOption1={'content'} trim={true}  />

                </Col>
                <Col>
                    <DataListSingle id={'Action1'}
                                    Store={Store} response={newElement === false ? response.Action : ''}

                                    name={'Action'} label={IntlMessageValue('de', 'common.sa.action', Store)}
                                    idInput={'Action'} fetch={Store.Action.objeckt}
                                    value1={'code'} value2={'content'} labelOption1={'content'} trim={true}  />

                </Col>
                <Col>
                    <DataListSingle id={'HERSTELLER1'}
                                    Store={Store} response={newElement === false ? response.HERSTELLER : ''}
                                    name={'HERSTELLER'} label={IntlMessageValue('de', 'common.sa.producer', Store)}
                                    idInput={'HERSTELLER'} fetch={Store.Producer.objeckt}
                                    value1={'id'} value2={'content'} labelOption1={'content'} trim={true}  />

                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <HTMLFetchSelect
                            fetch={Store.Objects.objeckt}
                            name={'OBJEKT'}
                            label={IntlMessageValue('de', 'common.sa.objects', Store)}
                            value={newElement === false ? secure(response.OBJEKT,'') : ''}
                            id={'OBJEKT'}
                            valueProperty={'id'}
                            Store={Store}
                            displayProperty={'content'} func={switchField} onchange={true}
                        />

                    </Row>
                </Col>
                <Col>
                    <Row id={'wc'}>
                        <HTMLFetchSelect
                            fetch={Store.PumpeWC.objeckt}
                            name={'WO'}
                            label={IntlMessageValue('de', 'common.sa.pumpeWC', Store)}
                            value={newElement === false ? secure(response.WO,'') : ''}
                            id={'WO'}
                            valueProperty={'id'}
                            displayProperty={'content'}
                        />
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <HTMLFetchSelect
                            fetch={Store.Symptom.objeckt}
                            name={'WAS'}
                            label={IntlMessageValue('de', 'common.sa.symptom', Store)}
                            value={newElement === false ? secure(response.WAS,'') : ''}
                            id={'WAS'}
                            valueProperty={'id'}
                            displayProperty={'content'}
                        />

                    </Row>
                </Col>
                <Col>
                    <Row id={'cc'}>
                        <HTMLFetchSelect
                            fetch={Store.PumpeCC.objeckt}
                            name={'WARUM'}
                            label={IntlMessageValue('de', 'common.sa.pumpeCC', Store)}
                            value={newElement === false ? secure(response.WARUM,'') : ''}
                            id={'WARUM'}
                            valueProperty={'id'}
                            displayProperty={'content'}
                        />
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <HTMLFetchSelect
                            fetch={Store.Environment.objeckt}
                            name={'Environment'}
                            label={IntlMessageValue('de', 'common.sa.environment', Store)}
                            value={newElement === false ? secure(response.Environment,'') : ''}
                            id={'Environment'} beforedisplayProperty={'id'}
                            valueProperty={'id'}
                            displayProperty={'content'}
                        />
                    </Row>
                </Col>
                <Col>
                    <Row>

                        <label className="form-label" htmlFor="PC">
                            <IntlMessage Store={Store} messageId="common.sa.pc" />:
                        </label>
                        <br/>
                        <Col>
                            <input name="PC"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.pc.provide', Store)}
                                   type="text"
                                   id="PC"
                                   className="form-control"
                                   defaultValue={newElement === false ? secure(response.PC,'') : ''}/>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <HTMLFetchSelect
                            fetch={Store.Country.objeckt}
                            name={'PRODSTELLE'}
                            label={IntlMessageValue('de', 'common.sa.country', Store)}
                            value={newElement === false ? secure(response.PRODSTELLE,'') : ''}
                            id={'PRODSTELLE'}
                            valueProperty={'id'}
                            displayProperty={'content'}
                        />
                    </Row>
                </Col>
                <Col>
                    <Row>

                        <label className="form-label" htmlFor="SERIENNR">
                            <IntlMessage Store={Store} messageId="common.sa.serial_number" />:
                        </label>
                        <br/>
                        <Col>
                            <input name="SERIENNR"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.serial_number.provide', Store)}
                                   type="text"
                                   id="SERIENNR"
                                   className="form-control"
                                   defaultValue={newElement === false ? secure(response.SERIENNR,'') : ''}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col><IntlMessage Store={Store} messageId="common.header.service_report" /></Col>
                <Col>
                    <Row>
                        <Col><IntlMessage Store={Store} messageId="common.sa.photo_documentation" />:</Col>
                        <Col>
                            <Form.Select name={'FOTODOK'} id={'FOTODOK'} aria-label="Default select example" defaultValue={newElement === false ? response.FOTODOK : '' }>
                                <option></option>
                                <option value="WAHR"><IntlMessage Store={Store} messageId="common.sa.photo_documentation.yes" /></option>
                                <option value=""><IntlMessage Store={Store} messageId="common.sa.photo_documentation.no" /></option>
                            </Form.Select></Col>
                    </Row>
                </Col>

            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="TECHNIKERB">
                        <Form.Control name="TECHNIKERB" id={'Servicebericht'} maxLength={1300} as="textarea" rows={6} defaultValue={newElement === false ? (response.TECHNIKERB !== null && response.TECHNIKERB !== undefined ? response.TECHNIKERB : '') + '\n' + '\n' + (response.TECHNIKERB1 !== null && response.TECHNIKERB1 !== undefined ? response.TECHNIKERB1 : '') : ''} />
                    </Form.Group>

                </Col>
            </Row>
            <Row>
                <Col><IntlMessage Store={Store} messageId="common.header.subsequent_service_order_required" /></Col>
                <Col>  {/*<input name="FOLGEAUFTR"
                              autoComplete="on"
                              placeholder={IntlMessageValue('de', 'common.sa.subsequent_service_order_required.provide', Store)}
                              type="text"
                              id="formFile1KdNr"
                              className="form-control"
                              defaultValue={newElement === false ? secure(response.FOLGEAUFTR,'') : ''}/>*/}

                    <Form.Select aria-label="Default select example" id={'FOLGEAUFTR'} name={'FOLGEAUFTR'} defaultValue={newElement === false ? secure(response.FOLGEAUFTR,'') : 'Nein'}>
                        <option></option>
                        <option value="Nein">Nein</option>
                        <option value="Ja">Ja</option>
                    </Form.Select>
                </Col>
               {/* <Col>
                    <input name="STATUS"
                              autoComplete="on"
                              placeholder={IntlMessageValue('de', 'common.sa.order_completed.provide', Store)}
                              type="text"
                              id="formFile1KdNr"
                              className="form-control"
                              defaultValue={newElement === false ? secure(response.STATUS,'') : ''}/></Col>*/}
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="INTERNEVER">
                        <Form.Label>Interne Vermerke (Wird in Ausdruck nicht angezeigt):</Form.Label>
                        <Form.Control name="INTERNEVER" as="textarea" rows={6} defaultValue={newElement === false ? secure(response.INTERNEVER,'') : ''} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col><IntlMessage Store={Store} messageId="common.header.parts" /></Col>
                <Col><IntlMessage Store={Store} messageId="common.header.designation" /></Col>
                <Col><IntlMessage Store={Store} messageId="common.header.number" /></Col>
                <Col><IntlMessage Store={Store} messageId="common.header.code" /></Col>
            </Row>
            <Row>
                <Col><input name="ARTIKEL1"
                            autoComplete="on"
                            placeholder={IntlMessageValue('de', 'common.sa.parts.provide', Store)}
                            type="text"
                            id="ARTIKEL1"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.ARTIKEL1,'') : ''}/></Col>
                <Col><input name="MATERIAL1"
                            autoComplete="on" maxLength={57}
                            placeholder={IntlMessageValue('de', 'common.sa.designation.provide', Store)}
                            type="text"
                            id="MATERIAL1"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.MATERIAL1,'') : ''}/></Col>
                <Col><input name="ANZAH1"
                            autoComplete="on"
                            placeholder={IntlMessageValue('de', 'common.sa.number.provide', Store)}
                            type="text"
                            id="ANZAH1"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.ANZAH1,'') : ''}/></Col>
                <Col>
                    <Form.Select aria-label="Default select example" id={'CODE1'} name={'CODE1'} defaultValue={newElement === false ? secure(response.CODE1,'') : ''}>
                        <option></option>
                        <option value="1"><IntlMessage Store={Store} messageId="common.header.code.bill" /></option>
                        <option value="2"><IntlMessage Store={Store} messageId="common.header.code.warranty" /></option>
                        <option value="3"><IntlMessage Store={Store} messageId="common.header.code.goodwill" /></option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <Col><input name="ARTIKEL2"
                            autoComplete="on"
                            placeholder={IntlMessageValue('de', 'common.sa.parts.provide', Store)}
                            type="text"
                            id="ARTIKEL2"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.ARTIKEL2,'') : ''}/></Col>
                <Col><input name="MATERIAL2"
                            autoComplete="on" maxLength={57}
                            placeholder={IntlMessageValue('de', 'common.sa.designation.provide', Store)}
                            type="text"
                            id="MATERIAL2"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.MATERIAL2,'') : ''}/></Col>
                <Col><input name="ANZAHL2"
                            autoComplete="on"
                            placeholder={IntlMessageValue('de', 'common.sa.number.provide', Store)}
                            type="text"
                            id="ANZAHL2"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.ANZAHL2,'') : ''}/></Col>
                <Col>
                    <Form.Select aria-label="Default select example" id={'CODE2'} name={'CODE2'} defaultValue={newElement === false ? secure(response.CODE2,'') : ''}>
                        <option></option>
                        <option value="1"><IntlMessage Store={Store} messageId="common.header.code.bill" /></option>
                        <option value="2"><IntlMessage Store={Store} messageId="common.header.code.warranty" /></option>
                        <option value="3"><IntlMessage Store={Store} messageId="common.header.code.goodwill" /></option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <Col><input name="ARTIKEL3"
                            autoComplete="on"
                            placeholder={IntlMessageValue('de', 'common.sa.parts.provide', Store)}
                            type="text"
                            id="ARTIKEL3"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.ARTIKEL3,'') : ''}/></Col>
                <Col><input name="MATERIAL3"
                            autoComplete="on" maxLength={57}
                            placeholder={IntlMessageValue('de', 'common.sa.designation.provide', Store)}
                            type="text"
                            id="MATERIAL3"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.MATERIAL3,'') : ''}/></Col>
                <Col><input name="ANZAHL3"
                            autoComplete="on"
                            placeholder={IntlMessageValue('de', 'common.sa.number.provide', Store)}
                            type="text"
                            id="ANZAHL3"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.ANZAHL3,'') : ''}/></Col>
                <Col>
                    <Form.Select aria-label="Default select example" id={'CODE3'} name={'CODE3'} defaultValue={newElement === false ? secure(response.CODE3,'') : ''}>
                        <option></option>
                        <option value="1"><IntlMessage Store={Store} messageId="common.header.code.bill" /></option>
                        <option value="2"><IntlMessage Store={Store} messageId="common.header.code.warranty" /></option>
                        <option value="3"><IntlMessage Store={Store} messageId="common.header.code.goodwill" /></option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <Col><input name="ARTIKEL4"
                            autoComplete="on"
                            placeholder={IntlMessageValue('de', 'common.sa.parts.provide', Store)}
                            type="text"
                            id="ARTIKEL4"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.ARTIKEL4,'') : ''}/></Col>
                <Col><input name="MATERIAL4"
                            autoComplete="on" maxLength={57}
                            placeholder={IntlMessageValue('de', 'common.sa.designation.provide', Store)}
                            type="text"
                            id="MATERIAL4"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.MATERIAL4,'') : ''}/></Col>
                <Col><input name="ANZAHL4"
                            autoComplete="on"
                            placeholder={IntlMessageValue('de', 'common.sa.number.provide', Store)}
                            type="text"
                            id="ANZAHL4"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.ANZAHL4,'') : ''}/></Col>
                <Col>
                    <Form.Select aria-label="Default select example" id={'CODE4'} name={'CODE4'} defaultValue={newElement === false ? secure(response.CODE4,'') : ''}>
                        <option></option>
                        <option value="1"><IntlMessage Store={Store} messageId="common.header.code.bill" /></option>
                        <option value="2"><IntlMessage Store={Store} messageId="common.header.code.warranty" /></option>
                        <option value="3"><IntlMessage Store={Store} messageId="common.header.code.goodwill" /></option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <Col><input name="ARTIKEL5"
                            autoComplete="on"
                            placeholder={IntlMessageValue('de', 'common.sa.parts.provide', Store)}
                            type="text"
                            id="ARTIKEL5"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.ARTIKEL5,'') : ''}/></Col>
                <Col><input name="MATERIAL5"
                            autoComplete="on"
                            placeholder={IntlMessageValue('de', 'common.sa.designation.provide', Store)}
                            type="text"
                            maxLength={57}
                            id="MATERIAL5"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.MATERIAL5,'') : ''}/></Col>
                <Col><input name="ANZAHL5"
                            autoComplete="on"
                            placeholder={IntlMessageValue('de', 'common.sa.number.provide', Store)}
                            type="text"
                            id="ANZAHL5"
                            className="form-control"
                            defaultValue={newElement === false ? secure(response.ANZAHL5,'') : ''}/></Col>
                <Col>
                    <Form.Select aria-label="Default select example" id={'CODE5'} name={'CODE5'} defaultValue={newElement === false ? secure(response.CODE5,'') : ''}>
                        <option></option>
                        <option value="1"><IntlMessage Store={Store} messageId="common.header.code.bill" /></option>
                        <option value="2"><IntlMessage Store={Store} messageId="common.header.code.warranty" /></option>
                        <option value="3"><IntlMessage Store={Store} messageId="common.header.code.goodwill" /></option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <Col><IntlMessage Store={Store} messageId="common.header.sa_technical" />:</Col>
                <Col>
                   <DataListSingle id={'test9'}
                                    Store={Store}
                                    response={newElement === false ? secure(response.TECHNIKER,'') : ''}
                                    name={'TECHNIKER'} label={''}
                                    idInput={'TECHNIKER'} fetch={JSON.parse(localStorage.getItem('AllUSerSa') as string)}
                                    value1={'first_name'} value2={'last_name'}  />
              </Col>
                <Col>
                    <DataListSingle id={'test44'}
                                    Store={Store}
                                    response={newElement === false ? secure(response.TECHNIKER2,'') : ''}
                                    name={'TECHNIKER2'} label={''}
                                    idInput={'TECHNIKER2'} fetch={JSON.parse(localStorage.getItem('AllUSerSa') as string)}
                                    value1={'first_name'} value2={'last_name'}  />
                   </Col>
                <Col>
                    <DataListSingle id={'test11'}
                                    Store={Store}
                                    response={newElement === false ? secure(response.TECHNIKER3,'') : ''}
                                    name={'TECHNIKER3'} label={''}
                                    idInput={'TECHNIKER3'} fetch={JSON.parse(localStorage.getItem('AllUSerSa') as string)}
                                    value1={'first_name'} value2={'last_name'}  />
                    </Col>
            </Row>
            {newElement === false ? (response.AUFTRAGSAR === 'Prüfbericht' ? <>
                    <Row>
                        <Col> <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">Befundungszeit:</InputGroup.Text>

                            <input name="Arbeitszeit"
                                   autoComplete="on"
                                   placeholder={'Bitte Befundungszeit Eingeben'}
                                   type="text"
                                   id="Befundungszeit"
                                   className="form-control"
                                   defaultValue={newElement === false ? secure(response.ARBEITSZEIT,'') : ''}/>
                            <InputGroup.Text id="basic-addon1">h</InputGroup.Text>
                        </InputGroup></Col>
                        <Col><InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">geschätzte Instandsetzungszeit:</InputGroup.Text>
                            <input name="Fahrzeit"
                                   autoComplete="on"
                                   placeholder={'Bitte geschätzte Instandsetzungszeit Eingeben'}
                                   type="text"
                                   id="Instandsetzungszeit"
                                   className="form-control"
                                   defaultValue={newElement === false ? secure(response.FAHRZEIT,'') : ''}/>  <InputGroup.Text id="basic-addon1">h</InputGroup.Text>
                        </InputGroup></Col>

                    </Row>

                </>
                :  <>
                    <Row>
                        <Col> <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><IntlMessage Store={Store} messageId="common.header.worktime" />:</InputGroup.Text>

                            <input name="Arbeitszeit"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.worktime.provide', Store)}
                                   type="text"
                                   id="formFile1KdNr"
                                   className="form-control"
                                   defaultValue={newElement === false ? secure(response.ARBEITSZEIT,'') : ''}/>
                            <InputGroup.Text id="basic-addon1">h</InputGroup.Text>
                        </InputGroup></Col>
                        <Col><InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><IntlMessage Store={Store} messageId="common.header.travel_time" />:</InputGroup.Text>
                            <input name="Fahrzeit"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.travel_time.provide', Store)}
                                   type="text"
                                   id="formFile1KdNr"
                                   className="form-control"
                                   defaultValue={newElement === false ? secure(response.FAHRZEIT,'') : ''}/>  <InputGroup.Text id="basic-addon1">h</InputGroup.Text>
                        </InputGroup></Col>
                        <Col> <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><IntlMessage Store={Store} messageId="common.header.driving_kilometers" />:</InputGroup.Text>
                            <input name="KM"
                                   autoComplete="on"
                                   placeholder={IntlMessageValue('de', 'common.sa.driving_kilometers.provide', Store)}
                                   type="text"
                                   id="formFile1KdNr"
                                   className="form-control"
                                   defaultValue={newElement === false ? secure(response.KM,'') : ''}/>  <InputGroup.Text id="basic-addon1">km</InputGroup.Text>
                        </InputGroup></Col>

                    </Row>
                </>

        ) : (<>
            <Row id={'Serviceauftrag'}>
                <Col> <InputGroup className="mb-3">
                    <InputGroup.Text id="worktime"><IntlMessage Store={Store} messageId="common.header.worktime" />:</InputGroup.Text>

                    <input name="Arbeitszeit"
                           autoComplete="on"
                           placeholder={IntlMessageValue('de', 'common.sa.worktime.provide', Store)}
                           type="text"
                           id="Inputworktime"
                           className="form-control"
                           defaultValue={newElement === false ? secure(response.ARBEITSZEIT,'') : ''}/>
                    <InputGroup.Text id="basic-addon1">h</InputGroup.Text>
                </InputGroup></Col>
                <Col><InputGroup className="mb-3">
                    <InputGroup.Text id="travel_time"><IntlMessage Store={Store} messageId="common.header.travel_time" />:</InputGroup.Text>
                    <input name="Fahrzeit"
                           autoComplete="on"
                           placeholder={IntlMessageValue('de', 'common.sa.travel_time.provide', Store)}
                           type="text"
                           id="Inputtravel_time"
                           className="form-control"
                           defaultValue={newElement === false ? secure(response.FAHRZEIT,'') : ''}/>  <InputGroup.Text id="basic-addon1">h</InputGroup.Text>
                </InputGroup></Col>
                <Col id={'driving_kilometers'}> <InputGroup className="mb-3">
                    <InputGroup.Text><IntlMessage Store={Store} messageId="common.header.driving_kilometers" />:</InputGroup.Text>
                    <input name="KM"
                           autoComplete="on"
                           placeholder={IntlMessageValue('de', 'common.sa.driving_kilometers.provide', Store)}
                           type="text"
                           id="formFile1KdNr"
                           className="form-control"
                           defaultValue={newElement === false ? secure(response.KM,'') : ''}/>  <InputGroup.Text id="basic-addon1">km</InputGroup.Text>
                </InputGroup></Col>

            </Row>

        </>)}
        </>
    )

}



const switchField = (type:any,idWC:any,idCC:any,Store:any) => {


    let ele = document.getElementById(idWC)
    if (ele !== null) {
        ele.innerHTML = '';
    }

    let mapping = [
        {type:0,wc:'PumpeWC',cc:'PumpeCC'},
        {type:1,wc:'PumpeWC',cc:'PumpeCC'},
        {type:2,wc:'MotorWC',cc:'MotorCC'},
        {type:3,wc:'ElectronicsWC',cc:'ElectronicsCC'},
        {type:4,wc:'SensorWC',cc:'SensorCC'},
        {type:5,wc:'OotherSCWC',cc:'OotherSCCC'},
        {type:6,wc:'SoftwareWC',cc:'SoftwareCC'},
        {type:7,wc:'CompleteSystemWC',cc:'CompleteSystemCC'}]

    let newElement =<HTMLFetchSelect
        fetch={Store[mapping[type].wc].objeckt}
        name={'WO'}
        label={IntlMessageValue('de', 'common.sa.pumpeWC', Store)}
        value={''}
        id={'WO'}
        valueProperty={'id'}
        displayProperty={'content'}
    />
    let temp = document.createElement('div')
    ReactDOM.render(newElement, temp)

    if (ele !== null) {
        ele.appendChild(temp)
    }
    let eles = document.getElementById(idCC)
    if (eles !== null) {
        eles.innerHTML = '';
    }

    let newElements =<HTMLFetchSelect
        fetch={Store[mapping[type].cc].objeckt}
        name={'WARUM'}
        label={IntlMessageValue('de', 'common.sa.pumpeCC', Store)}
        value={''}
        id={'WARUM'}
        valueProperty={'id'}
        displayProperty={'content'}
    />
    let temps = document.createElement('div')
    ReactDOM.render(newElements, temps)
    if (eles !== null) {
        eles.appendChild(temps)
    }


}

const DisplayStatus = (id:any) => {
    let StatusSa = document.getElementById('statesa') as HTMLSelectElement;
    let StatusW = document.getElementById('statew') as HTMLSelectElement;

    if(id === 'Serviceauftrag') {
        if(StatusSa !== null && StatusW !== null) {
            StatusSa.style.display = 'block';
            StatusW.style.display = 'none';
        }
    } else if(id === 'Prüfbericht') {
        if (StatusSa !== null && StatusW !== null) {
            StatusW.style.display = 'block';
            StatusSa.style.display = 'none';
        }
    }
}

const switcher = (id:any,Store:any) => {

    let Labelworktime = document.getElementById('worktime') as HTMLDivElement;
    let Inputworktime = document.getElementById('Inputworktime') as HTMLInputElement;
    let Labeltravel_time = document.getElementById('travel_time') as HTMLDivElement;
    let Inputtravel_time = document.getElementById('Inputtravel_time') as HTMLInputElement;
    let Cointainerdriving_kilometers = document.getElementById('driving_kilometers') as HTMLDivElement;
    let pruefbericht_status = document.getElementById('pruefbericht_status') as HTMLSelectElement;
    let servicebericht_status = document.getElementById('servicebericht_status') as HTMLSelectElement;



    if(id === 'Prüfbericht') {
        Labelworktime.innerHTML = 'Befundungszeit:'
        Inputworktime.placeholder = 'Bitte Befundungszeit eingeben'
        Labeltravel_time.innerHTML = 'geschätzte Instandsetzungszeit:'
        Inputtravel_time.placeholder = 'Bitte geschätzte Instandsetzungszeit eingeben'
        Cointainerdriving_kilometers.style.display = 'none';
        pruefbericht_status.style.display = 'block';
        pruefbericht_status.setAttribute('name','STATUS')
        servicebericht_status.style.display = 'none'
        servicebericht_status.removeAttribute('name')
    } else {
        Labelworktime.innerHTML = IntlMessageValue('de', 'common.header.worktime', Store) +':';
        Inputworktime.placeholder = IntlMessageValue('de', 'common.sa.worktime.provide', Store)
        Labeltravel_time.innerHTML = IntlMessageValue('de', 'common.header.travel_time', Store) +':';
        Inputtravel_time.placeholder = IntlMessageValue('de', 'common.sa.travel_time.provide', Store)
        Cointainerdriving_kilometers.style.display = 'block';
        pruefbericht_status.style.display = 'none';
        pruefbericht_status.removeAttribute('name')
        servicebericht_status.style.display = 'block'
        servicebericht_status.setAttribute('name','STATUS')
    }

    DisplayStatus(id);


}
