import {SoftwareWCActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const SoftwareWCRequestInit = () => {
    return {
        type:SoftwareWCActionType.SoftwareWC_INIT
    }
}

export const SoftwareWCSuccess = (
    objeckt: any
) => {
    return {
        type: SoftwareWCActionType.SoftwareWC_INIT,
        objeckt: objeckt
    }
}

export const getSoftwareWC = (
    objeckt: any[]
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            SoftwareWCSuccess(
                objeckt
            )
        )
    }
}
