import React from 'react'
import PageOverview from './Pages/Pageoverview'
import PageDetail from './Pages/PageDetail'
import PageNew from './Pages/new'
import CalenderDeleted from './Pages/deleted'

export const kalenderPagesConfig = (value: any) => {
    return [
        {
            path: '/kalender/overview/:page',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'consultant','engineer']),
            elements: <PageOverview value={value} />
        },
        {
            path: '/kalender/Detail/:id',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'consultant','engineer']),
            elements: <PageDetail value={value} />
        },
        {
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'consultant','engineer']),
            path: '/kalender/Delete/:page/:deleteID',
            elements: <CalenderDeleted value={value} />
        },
        {
            path: '/kalender/new/:page',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'consultant','engineer']),
            elements: <PageNew value={value} />
        }
    ]
}
