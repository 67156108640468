export enum CompleteSystemCCActionType {
    CompleteSystemCC_INIT = 'CompleteSystemCC_INIT'
}

export enum CompleteSystemWCActionType {
    CompleteSystemWC_INIT = 'CompleteSystemWC_INIT'
}

export enum ElectronicsCCActionType {
    ElectronicsCC_INIT = 'ElectronicsCC_INIT'
}

export enum ElectronicsWCActionType {
    ElectronicsWC_INIT = 'ElectronicsWC_INIT'
}

export enum MotorCCActionType {
    MotorCC_INIT = 'MotorCC_INIT'
}

export enum MotorWCActionType {
    MotorWC_INIT = 'MotorWC_INIT'
}

export enum OotherSCCCActionType {
    OotherSCCC_INIT = 'OotherSCCC_INIT'
}

export enum OotherSCWCActionType {
    OotherSCWC_INIT = 'OotherSCWC_INIT'
}

export enum PumpeCCActionType {
    PumpeCC_INIT = 'PumpeCC_INIT'
}

export enum PumpeWCActionType {
    PumpeWC_INIT = 'PumpeWC_INIT'
}

export enum SensorCCActionType {
    SensorCC_INIT = 'SensorCC_INIT'
}

export enum SensorWCActionType {
    SensorWC_INIT = 'SensorWC_INIT'
}

export enum SoftwareCCActionType {
    SoftwareCC_INIT = 'SoftwareCC_INIT'
}

export enum SoftwareWCActionType {
    SoftwareWC_INIT = 'SoftwareWC_INIT'
}

export enum ObjectsActionType {
    Objects_INIT = 'Objects_INIT'
}

export enum CountryActionType {
    Country_INIT = 'Country_INIT'
}

export enum SymptomActionType {
    Symptom_INIT = 'Symptom_INIT'
}

export enum ApplicationActionType {
    Application_INIT = 'Application_INIT'
}

export enum EnvironmentActionType {
    Environment_INIT = 'Environment_INIT'
}

export enum ProducerActionType {
    Producer_INIT = 'Producer_INIT'
}

export enum ActionActionType {
    Action_INIT = 'Action_INIT'
}

export enum AgdActionType {
    Agd_INIT = 'Agd_INIT'
}

export enum KdActionType {
    Kd_INIT = 'KD_INIT'
}
