import {IntlMessageValue} from "../../../../@WUM/core/component/const/IntlMessage";


const general = (conf: string,
                 response: any,
                 newElement: any = false,
                 Store: any,
                 setcurrenttab:any) => {
    return [

        {
            formType: 'divider',
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tab.general.product_number', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.tab.general.product_number.provide', Store),
                    name: 'PRODUKTNR',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.PRODUKTNR : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tab.general.serial_number', Store),
                    required: true,
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.tab.general.serial_number.provide', Store),
                    name: 'SERIENNR',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.SERIENNR : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tab.general.date_of_manufacture', Store),
                    type: 'date',
                    placeholder: IntlMessageValue('de', 'common.vde.tab.general.date_of_manufacture.provide', Store),
                    name: '',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.HERSTELLER : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tab.general.manufacture', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.tab.general.manufacture.provide', Store),
                    name: 'HERSTELLER',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.HERSTELLER : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tab.general.location', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.tab.general.location.provide', Store),
                    name: 'vde[Standort]',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.vde.Standort : ''
                }
            ]
        },
        {
            formType: 'divider',
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tab.general.testing_device', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.tab.general.testing_device.provide', Store),
                    name: 'vde[Pruefgeraet1]',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.vde.Pruefgeraet1 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tab.general.serial_number', Store),
                    required: true,
                    type: 'text',
                    readonly: newElement === false ? true : false,
                    placeholder: IntlMessageValue('de', 'common.vde.tab.general.serial_number.provide', Store),
                    name: 'vde[PgSN1]',
                    value: newElement === false ? response.vde.PgSN1 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tab.general.technician', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.tab.general.technician.provide', Store),
                    name: 'Techniker',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.Techniker : ''
                }
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tab.general.testing_device2', Store),
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.tab.general.testing_device2.provide', Store),
                    name: 'vde[Pruefgeraet2]',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.vde.Pruefgeraet2 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tab.general.serial_number', Store),
                    required: true,
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.vde.tab.general.serial_number.provide', Store),
                    name: 'vde[PgSN2]',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.vde.PgSN2 : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.vde.tab.general.date', Store),
                    type: 'date',
                    placeholder: IntlMessageValue('de', 'common.vde.tab.general.date.provide', Store),
                    name: 'DATUM',
                    readonly: newElement === false ? true : false,
                    value: newElement === false ? response.DATUM : ''
                }
            ]
        },
        {
            formType: 'buttonTabeMaker',
            next:'showcheck',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.vde.tab.general.button', Store),
            class: 'float-end mr-3 mt-5'
        },
        {
            formType: 'description',
            value: 'lang[common.tab.maker.general.data.info]'
        }
    ]
}

export default general;
