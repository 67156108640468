
import updateDataMappingProps from '../@WUM/core/component/core/interface/updateDataMappingProps.interface'
import { faqApiType } from '../@WUM/helpdesk/api/request/faqApi.interface'
import {
    configFAQ,
    outputFAQ
} from '../@WUM/helpdesk/modules/Pages/configuration/mapping'

const updateDataMapping = (props: updateDataMappingProps) => {
    switch (props.part) {
        case 'faq':
            let requestFAQ: faqApiType[] & configFAQ = Object.assign(
                {},
                props.currentTutorial,
                outputFAQ(props.Data)
            )
            return requestFAQ
    }
}

export default updateDataMapping
