import {IntlMessageValue} from "../../../../@WUM/core/component/const/IntlMessage";

const skii = (conf: string,
             response: any,
             newElement: any = false,
             Store: any,
              setcurrenttab:any) => {
    return [
        {
            formType: 'header',
            value: IntlMessageValue('de', 'common.skii.tab.header', Store)
        },
        {
            formType: 'buttonTabeMaker',
            next:'showcheck',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.skii.tab.button.showcheck', Store),
            withBr:true,
            class:'mb-3'
        },
        {
            formType: 'multiply',
            class:'mb-5',
            cols: [
                {
                    formType: 'buttonTabeMaker',
                    next:'Riso',
                    setter:setcurrenttab,
                    label:IntlMessageValue('de', 'common.skii.tab.button.riso', Store),
                    withBr:true
                },
                {
                    formType: 'buttonTabeMaker',
                    next:'IBR',
                    setter:setcurrenttab,
                    label:IntlMessageValue('de', 'common.skii.tab.button.ibr', Store),
                    withBr:true
                }
            ]
        },
        {
            formType: 'buttonTabeMaker',
            next:'Auswertung',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.skii.tab.button.protocol', Store),
            withBr:true
        }
    ]
}

export default skii;
