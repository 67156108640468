
const dateTimeHelper = ( time: string, format: string , currentFormat: string, delimiter: string ) => {

    if(time === undefined) {
        return;
    }
    let params = currentFormat.split(delimiter);
    let date = time.split(delimiter);
    let year = "",month = "",day = "";
    if(params === undefined || date === undefined || date.length === 1)
        return time;
    for (let i =0; i < params.length;i++) {
        if(params[i].substring(0,1) === "Y" || params[i].substring(0,1) === "y") {
                if(date[i].length < 3) {
                    date[i] = (parseInt(date[i]) > 50 ) ? "19"+date[i] : "20"+date[i];
                }
                year = date[i];

        } else if(params[i].substring(0,1) === "M" || params[i].substring(0,1) === "m")
        {
            month = date[i];

        } else if(params[i].substring(0,1) === "D" || params[i].substring(0,1) === "d")
        {
            day = date[i];

        }

    }
    const result = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    //return result.toLocaleDateString('%Y-%m-%d');
    return year+"-"+ month.padStart(2,"0")+"-"+day.padStart(2,"0");
}

export default dateTimeHelper

