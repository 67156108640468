import { AuthPagesConfig } from '../@WUM/templates/default/Auth/modules'
import MergeTwoObjecttoArray from '../@WUM/core/component/const/MergeTwoObjecttoArray'
import components from './RegisterComponents'
import { useSelector } from 'react-redux'
import {DashbaordPagesConfig} from "../modules/dashboard/modules";
import {CalenderPagesConfig} from "../modules/calender";
import {CustomerPagesConfig} from "../modules/kundenverwaltung";
import {vdePagesConfig} from "../modules/vde";
import {serviceauftragPagesConfig} from "../modules/serviceauftrag";
import {kalenderPagesConfig} from "../modules/kalender";
import {MemberPagesConfig} from "../modules/Member";
import {serviceauftragGrundfosPagesConfig} from "../modules/serviceauftragGrundfos";
import { TicketPagesConfig } from '../modules/ticketsystem/modules'

export const RegisterRoute = (value: any) => {
    const Store = useSelector((state: any) => state)

    let TMPRegister = []
    let TMPRegisterIndividual
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            TMPRegister = [
                ...AuthPagesConfig(value),
                ...DashbaordPagesConfig(value),
                ...CalenderPagesConfig(value),
                ...CustomerPagesConfig(value),
                ...vdePagesConfig(value),
                ...serviceauftragPagesConfig(value),
                ...kalenderPagesConfig(value),
                ...MemberPagesConfig(value),
                ...TicketPagesConfig(value),
                ...serviceauftragGrundfosPagesConfig(value)
            ]
            break
        case 'CMSwithMatomo':
            TMPRegister = [
                ...AuthPagesConfig(value),
                ...DashbaordPagesConfig(value),
                ...CalenderPagesConfig(value),
                ...CustomerPagesConfig(value),
                ...vdePagesConfig(value),
                ...serviceauftragPagesConfig(value),
                ...kalenderPagesConfig(value),
                ...MemberPagesConfig(value),
                ...TicketPagesConfig(value),
                ...serviceauftragGrundfosPagesConfig(value)
            ]
            break
        case 'SHOP':
        case 'ProductCatalog':
            TMPRegister = [
                ...AuthPagesConfig(value),
                ...DashbaordPagesConfig(value),
                ...CalenderPagesConfig(value),
                ...CustomerPagesConfig(value),
                ...vdePagesConfig(value),
                ...serviceauftragPagesConfig(value),
                ...kalenderPagesConfig(value),
                ...MemberPagesConfig(value),
                ...TicketPagesConfig(value),
                ...serviceauftragGrundfosPagesConfig(value)
            ]
            break
        case 'SHOPwithMatomo':
        case 'ProductCatalogwithMatomo':
            TMPRegister = [
                ...AuthPagesConfig(value),
                ...DashbaordPagesConfig(value),
                ...CalenderPagesConfig(value),
                ...CustomerPagesConfig(value),
                ...vdePagesConfig(value),
                ...serviceauftragPagesConfig(value),
                ...kalenderPagesConfig(value),
                ...MemberPagesConfig(value),
                ...TicketPagesConfig(value),
                ...serviceauftragGrundfosPagesConfig(value)
            ]
            break
        default:
            TMPRegister = [
                ...AuthPagesConfig(value),
                ...DashbaordPagesConfig(value),
                ...CalenderPagesConfig(value),
                ...CustomerPagesConfig(value),
                ...vdePagesConfig(value),
                ...serviceauftragPagesConfig(value),
                ...kalenderPagesConfig(value),
                ...MemberPagesConfig(value),
                ...TicketPagesConfig(value),
                ...serviceauftragGrundfosPagesConfig(value)
            ]
    }
    TMPRegisterIndividual = []

    if (
        process.env.REACT_APP_DB_MODULS !== undefined &&
        Store.dynamikModuls.PagesModuls.length > 0 &&
        !Store.dynamikModuls.PagesModuls[0].hasOwnProperty('msg')
    ) {
        for (let x = 0; x < Store.dynamikModuls.PagesModuls.length; x++) {
            let search = Store.dynamikModuls.PagesModuls[x].namespace

            TMPRegisterIndividual = MergeTwoObjecttoArray(
                TMPRegisterIndividual,
                /* @ts-ignore */
                components[search](value)
            )
        }
    }
    TMPRegisterIndividual = MergeTwoObjecttoArray(
        TMPRegisterIndividual,
        DashbaordPagesConfig(value)
    )

    if (process.env.REACT_APP_DB_MODULS === undefined) {
        return [...TMPRegister]
    } else {
        if (localStorage.getItem('userRole') === 'superadmin') {
            return [...TMPRegister, ...TMPRegisterIndividual]
        } else {
            return [...TMPRegisterIndividual]
        }
    }
}
