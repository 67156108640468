import {PumpeWCActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const PumpeWCRequestInit = () => {
    return {
        type:PumpeWCActionType.PumpeWC_INIT
    }
}

export const PumpeWCSuccess = (
    objeckt: any
) => {
    return {
        type: PumpeWCActionType.PumpeWC_INIT,
        objeckt: objeckt
    }
}

export const getPumpeWC = (
    objeckt: any[]
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            PumpeWCSuccess(
                objeckt
            )
        )
    }
}
