import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { saveDataIndividualGet } from '../../../@WUM/core/RestFullApi/ApiHook'
import { error } from '../../../@WUM/core/component/const/error.services'
import { removeUseLocalStorage } from '../../../@WUM/core/component/const/useLocalStoreage'
import { SpinnerJSX } from '../../../@WUM/core/component/const/Spinner'
import IntlMessage from '../../../@WUM/core/component/const/IntlMessage'
import {
    ResponseCalender,
    ResponseCalenderDelete
} from '../API/response/calender/calender'
import { useSelector } from 'react-redux'
import SearchJSX from '../../../@WUM/core/component/const/SearchJSX'
import TableMaker from '../../../@WUM/core/tablemaker'
import PaginationJSX from '../../../@WUM/core/form-serilizer/elements/pagination'
import { handleSucces } from '../../../@WUM/core/component/const/HandleSucces'

const PageOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    removeUseLocalStorage('seo_title')
    removeUseLocalStorage('seo_description')

    const history = useNavigate()
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths: string) => {
        let path = paths
        history(path)
    }
    let limit: any
    if (deleteID) {
        ResponseCalenderDelete(deleteID)
        routeChange('/customer/overview/' + page)
    }
    if (typeof page === 'string') {
        limit = parseFloat(page) * 15 - 15
    }
    const destination = 'customer'
    const namespace_search = 'customer_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    const response = ResponseCalender(limit, searchValue)
    const [parent_id, setparent_id] = useState(false)
    const row: any = []
    if (parent_id === false) {
        localStorage.removeItem('fetchSelect')
        const getPagesSelect = () => {
            saveDataIndividualGet('/Content/Pages/Select/0/')
                .then((data: any) => {
                    row.push(data)
                    localStorage.setItem('fetchSelect', JSON.stringify(row))
                })
                .catch((e) => {
                    error(e)
                })
        }
        getPagesSelect()
        setparent_id(true)
    }

    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_name',
                    translation: 'common.name',
                    param: 'Name1',
                    method: 'like'
                },
                {
                    id: 'search_EMail',
                    translation: 'common.email',
                    param: 'Email',
                    method: 'like'
                },
                {
                    id: 'search_DESTDEBNR',
                    translation: 'Kundennummer',
                    param: 'DESTDEBNR',
                    method: 'like'
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'customer.overview.button.new'
            ]
        }
    ]


    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: ['SpKzJn','DESTDEBNR', 'Name1', 'EMail', 'Strasse', 'Plz', 'Ort','Telefon1'],
            type: ['PumpenwolfKdIcon','string', 'string', 'string','string', 'string', 'string', 'string']
        },
        tableHeader: [
            'Sp.',
            <IntlMessage Store={Store} messageId="customer.table.kdnr" />,
            <IntlMessage Store={Store} messageId="customer.table.name" />,
            <IntlMessage Store={Store} messageId="customer.table.email" />,
            <IntlMessage Store={Store} messageId="customer.table.street" />,
            <IntlMessage Store={Store} messageId="customer.table.zip" />,
            <IntlMessage Store={Store} messageId="customer.table.city" />,
            <IntlMessage Store={Store} messageId="customer.table.tel1" />
        ],
        tableHeaderWidth: [ 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                <IntlMessage messageId="common.edit" Store={Store} />,
                <IntlMessage messageId="common.delete" Store={Store} />
            ],
            menuroute: [
                '/customer/Detail',
                '/customer/Delete/' + page + ''
            ],
            menuParams: ['', 'id', 'id'],
            menuIcons: ['pencil', 'Delete']
        }
    }
    if (response.length > 0) {
        return (
            <>
                <SearchJSX
                    setSearchValue={setSearchValue}
                    Store={Store} OnlyAnd={true}
                    SearchConfig={SearchConfig}
                />
                <TableMaker Configuration={Configuration} />
                <PaginationJSX
                    response={response}
                    history={history}
                    to={'overview'}
                    maxPage={limit}
                    handleSuccess={handleSucces}
                    destination={destination}
                />
                {/* {
                    <ContentSwitch
                        pagenew={'new'}
                        destinationnew={'Calender'}
                        tonew={'new'}
                        text={
                            <IntlMessage
                                Store={Store}
                                locale={'de'}
                                messageId={'omment.event.new'}
                            />
                        }
                        response={response}
                        CardsConfiguration={CardsConfiguration}
                        mode={mode}
                        Configuration={Configuration}
                        setMode={setMode}
                        destination={destination}
                        page={page}
                        to={'overview'}
                        setSearchValues={setSearchValue}
                        setSearchs={setSearch}
                        search={false}
                        modalShow={modalShow}
                        setModalShow={setModalShow}
                        modalData={modalData}
                        setModalData={setModalData}
                        SearchParam={'?translation.title'}
                        searchfield={false}
                        allTaxon={[]}
                        _handleKeyFilters={[]}
                        _handleEnter={[]}
                        filter={true}
                        stop={false}
                        Store={Store}
                    />
                }*/}
            </>
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default PageOverview
