import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {SpinnerJSX} from '../../../@WUM/core/component/const/Spinner'
import IntlMessage, {IntlMessageValue} from '../../../@WUM/core/component/const/IntlMessage'
import {
    ResponseALLUser,
    ResponseCalender,
    ResponseCalenderDevice,
    ResponseUserCalender
} from "../API/response/calender/calender";
import {useSelector} from 'react-redux'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/de';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {ResponseCalenderCheck} from "../API/response/calender/calender";
import OutlookCalenderDeviceRequest from "../../../@WUM/core/component/const/OutlookCalenderDeviceRequest";
import DynamikModal from "../../../@WUM/core/component/const/DynamikModal";
import Button from "react-bootstrap/Button";
import PielersForm from '../../../@WUM/core/form-serilizer/index'
import {init2} from "../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {ApiURL} from "../../../@WUM/core/component/const/ApiURL";
import {getToken} from "../../../@WUM/core/component/const/getToken";
import Badge from 'react-bootstrap/Badge';
import "./../css/index.css"
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ApiRoot} from "../../../@WUM/core/RestFullApi/api";
import {CalenderEventInterface} from "../API/request/calender/CalenderEvent.interface";

const PageOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    const [user, setUser] = useState([{name: localStorage.getItem('username'),id:localStorage.getItem('userid'),time: []}])

    function KalenderWoche(t?:any,j?:any,m?:any) {
        var Datum = new Date();
        if (!t) {
            j = Datum.getFullYear(); if (1900 > j) j +=1900;
            m = Datum.getMonth(); t = Datum.getDate();
        }
        else m--;
        Datum = new Date(j,m,t,0,0,1);
        var tag = Datum.getDay(); if (tag == 0) tag = 7;
        var d = new Date(2004,0,1).getTimezoneOffset();
        var Sommerzeit = (Date.UTC(j,m,t,0,d,1) - Number(Datum)) /3600000;
        Datum.setTime(Number(Datum) + Sommerzeit*3600000 - (tag-1)*86400000);
        var Jahr = Datum.getFullYear(); if (1900 > Jahr) Jahr +=1900;
        var kw = 1;
        if (new Date(Jahr,11,29) > Datum) {
            var Start = new Date(Jahr,0,1);
            Start = new Date(Number(Start) + 86400000*(8-Start.getDay()));
            if(Start.getDate() > 4) Start.setTime(Number(Start) - 604800000);
            // @ts-ignore
            kw = Math.ceil((Datum.getTime() - Start) /604800000);
        }
        return kw;
    }
    function getDatumVonWoche(jahr:any, kw:any) {
        // Set the first day of the year to Monday (assuming ISO 8601 standard)
        const ersterTagJahr = new Date(jahr, 0, 1);
        ersterTagJahr.setDate(ersterTagJahr.getDate() + (8 - ersterTagJahr.getDay()));

        // Calculate the number of days to add to the first day to reach the start of the week
        const tageBisWochenanfang = (kw - 1) * 7;

        // Calculate the date for the first day of the week (e.g., Monday)
        const ersterTagWoche = new Date(Number(ersterTagJahr) + tageBisWochenanfang * 86400000);

        // Calculate the number of days in the week
        const tageInWoche = 7;

        // Calculate the date for the last day of the week (e.g., Sunday)
        const letzterTagWoche = new Date(Number(ersterTagWoche) + (tageInWoche - 1) * 86400000);

        return {
            ersterTagWoche,
            letzterTagWoche
        };
    }


    /*const [user, setUser] = useState('37')*/
    let heute = getDatumVonWoche('2024',KalenderWoche()-1).ersterTagWoche
    let Heute = heute.getFullYear()+'-'+(heute.getMonth()+1).toString().padStart(2, '0')+'-'+heute.getDate().toString().padStart(2, '0')+'T00:00';
    let next7 = getDatumVonWoche('2024',KalenderWoche()-1).letzterTagWoche
    let next7Days = next7.getFullYear()+'-'+(next7.getMonth()+1).toString().padStart(2, '0')+'-'+next7.getDate().toString().padStart(2, '0')+'T23:59';

    const [start, setStart] = useState(Heute)
    const [end, setEnd] = useState(next7Days)
    const check = ResponseCalenderCheck(localStorage.getItem('userid') as string)

    const response2 = ResponseUserCalender((localStorage.getItem('userid') as string),start,end,user)

    const deviceCall = ResponseCalenderDevice();

    let limit: any

    if (typeof page === 'string') {
        limit = parseFloat(page) * 15 - 15
    }

    const setedUSer=(id:any,name:any,e:any) => {
        SetData(user,start,end)

        if(e.target.checked === false) {
            let tmpUser = [];
            for(let u=0;u<user.length;u++) {
                if(user[u].id === id) {

                } else {
                    tmpUser.push(user[u])
                }

            }
            setUser(tmpUser);
        } else {
            setUser([...user, {name: name,id:id,time: []}]);
        }


    }
    const SetData = (user:any,start:any,end:any) => {
        const FetchData = (user: any, start: any, end: any) => {

            let requestOptions = {
                method: 'GET',
                headers: {
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                }
            }

            fetch(ApiRoot() + "outlook/user/events/" + user.id + "/?filter=range&start=" + start + "&end=" + end, requestOptions)
                .then(async (response) => {
                    // Check for successful response status code (usually 200)
                    if (!response.ok) {
                        throw new Error(`Error fetching data: ${response.status}`);
                    }
                    return await response.json(); // Parse the JSON response
                })
                .then((result) => {
                    user.time = result
                    return result; // You can optionally return the data here
                })
                .catch((error) =>  user.time = []);
        return user;
        }
        for(let s=0;s<user.length;s++) {
            user[s].time = FetchData(user[s],start,end)
        }
        return user;
    }
    const response = ResponseCalender(limit, '')
    const [showDeviceRequest, setShowDeviceRequest] = useState<boolean>(false)
     const [shoDeviceUrl, setShoDeviceUrl] = useState<boolean>(false)
    const [DeviceUrl, setDeviceUrl] = useState<string>("")
    const [deviceCode, setDeviceCode] = useState<string>("")



    const AllUSer = ResponseALLUser('1');
    let USerHolderCheck = [];
    let USerHold = <></>
    for(let x =0;x<AllUSer.length;x++) {
        if (AllUSer[x].id+'' === localStorage.getItem('userid') as string) {
          USerHolderCheck.push(

               <Form.Check className={'float-start mr-3'} // prettier-ignore
                           type={'checkbox'}
                           label={AllUSer[x].username}
                           checked={true}
                           onClick={(e)=>setedUSer(AllUSer[x].id,AllUSer[x].username,e)}
               />

           )
       } else {
            USerHolderCheck.push(<>
                {/*<option value={AllUSer[x].id}>{AllUSer[x].username}</option>*/}
            <Form.Check className={'float-start mr-3'} // prettier-ignore
                            type={'checkbox'}
                            label={AllUSer[x].username}
                            onClick={(e)=>setedUSer(AllUSer[x].id,AllUSer[x].username,e)}
                /></>
            )
             }
            /*USerHold = <>
                <Form.Select aria-label="Default select example" defaultValue={user} onChange={(e) => {setUser(e.target.value),setUserName(e.target.innerText)}}>
                    {USerHolderCheck}
                </Form.Select>
            </>*/
        }

    if(check.state === 0 && shoDeviceUrl === false) {
        setShoDeviceUrl(true);
    }

    moment.locale("de")
    moment.updateLocale("de", {
        week: {
            dow: 1,
            doy: 4,
        },
    });
    const localizer = momentLocalizer(moment) // or globalizeLocalizer

    const connectDevice = () => {
        setShowDeviceRequest(true)
        setDeviceUrl(deviceCall!.verification_uri);
        setDeviceCode(deviceCall!.message);
        let style:any = document.getElementById("ModalOutlookDevice")?.style!;
        style.display = "block";
    }
    const [x, setx] = useState(1)
    const switchToShowDeviceCOde =()  => {


        let style:any = document.getElementById("ModalOutlookDevice")?.style!;
        style.display = "none";
        setShowDeviceRequest(false)
        document.getElementById("ModalOutlookDevice")?.remove()
        setShoDeviceUrl(true)
    }

    /*let Event = [
            {
                "title": "test1",
                "start": new Date(2023,9,17,22,24,17),
                "end": new Date(2023,9,18,22,24,20),
                "allday": true
            },
            {
                "title": "test2",
                "start": new Date(2023,8,29,22,24,17),
                "end": new Date(2023,8,29,22,50,20),
                "allday": true
            },
            {
                "title": "test3",
                "start": new Date(2023,8,25,22,24,17),
                "end": new Date(2023,8,25,22,50,20),
                "allday": false
            },
            {
                "title": "test termin 2",
                "start": new Date(2024,0,5,7,0,0),
                "end": new Date(2024,0,5,7,0,0),
                "allday": false
            }
        ]*/

    /*const TMpResponse = [


        {name: "Max Mustermann"},
        {
            time: [
                {
                    "title": "test termin 2",
                    "startDate": {
                        "year": "2024",
                        "month": 0,
                        "day": "05",
                        "hours": "07",
                        "minutes": "00",
                        "second": "00"
                    },
                    "endDate": {
                        "year": "2024",
                        "month": 0,
                        "day": "05",
                        "hours": "07",
                        "minutes": "30",
                        "second": "00"
                    },
                    "allday": false
                }
            ]
        },

                ,
        {name: "Frank Mustermann"},
        {
            time: [
        {
            "title": "test termin 2",
            "startDate": {
                "year": "2024",
                "month": 0,
                "day": "05",
                "hours": "07",
                "minutes": "00",
                "second": "00"
            },
            "endDate": {
                "year": "2024",
                "month": 0,
                "day": "05",
                "hours": "07",
                "minutes": "30",
                "second": "00"
            },
            "allday": false
        }
            ]
        },

   ]*/

    const messages = {
        allDay: 'Alle Tage',
        previous: '<',
        next: '>',
        today: 'Heute',
        month: '',
        week: 'Woche',
        work_week: 'Arbeitswoche',
        day: '',
        agenda: 'Monat Agenda',
        date: 'Datum',
        time: 'Zeit',
        event: 'Event'
    }

    const BuildCalenderDynamik = [];
    const setDate = (e:any) => {
        let MonthStart = e[0].getMonth() + 1;
        let MonthEnd = e[e.length - 1].getMonth() + 1
        let start = e[0].getFullYear()+'-'+('0'+MonthStart).slice(-2)+'-'+('0'+e[0].getDate()).slice(-2);
        let end = e[e.length - 1].getFullYear()+'-'+('0'+MonthEnd).slice(-2)+'-'+('0'+e[e.length - 1].getDate()).slice(-2)
        setStart(start+'T00:00')
        setEnd(end+'T23:59')
      /*  console.log(start+'T00:00')
        console.log(end+'T23:59')
        console.log(e[0].getMonth() + 1)*/
    }

    /*console.log(response2)
    console.log(TMpResponse)*/
    let CalenderHolder = [];
    // @ts-ignore
    if(response2['37'] !== undefined && response2['37'].length > 0 && response2 !== undefined && response2 !== null) {



    for(let u=0;u<user.length;u++)
        {


            let TMPTimeHolder = []

            let userid = user[u].id !== undefined && user[u].id !== null ? user[u].id : '0'

            // @ts-ignore
            if(response2[userid] !== undefined) {
                // @ts-ignore
                for (let t = 0; t < response2[userid].length; t++) {

                    // @ts-ignore
                    let TimeData = response2[userid][t]

                    TMPTimeHolder.push(
                        {
                            "title": TimeData.title,
                            "start": new Date(
                                // @ts-ignore
                                TimeData.startDate.year, TimeData.startDate.month, TimeData.startDate.day, TimeData.startDate.hours, TimeData.startDate.minutes, TimeData.startDate.second),
                            "end": new Date(
                                // @ts-ignore
                                TimeData.endDate.year, TimeData.endDate.month, TimeData.endDate.day, TimeData.endDate.hours, TimeData.endDate.minutes, TimeData.endDate.second),
                            "allday": TimeData.allday
                        }
                    )
                }

                /* console.log(TMPTimeHolder)*/
                CalenderHolder.push(
                    <>
                        <div style={{float:'left', marginRight: '15px', marginBottom: '20px',marginTop: '20px'}}>
                            <p>Username: {user[u].name}</p>

                            <Calendar
                                messages={messages}
                                localizer={localizer}
                                date={start}
                                startAccessor="start"
                                min={new Date(2017, 10, 0, 5, 0, 0)}
                                max={new Date(2017, 10, 0, 21, 0, 0)}
                                endAccessor="end"
                                views={['work_week', 'month']}
                                defaultView={'work_week'}
                                events={TMPTimeHolder}
                                style={{maxWidth: "750px", minWidth: '750px', float: 'left', background: '#EFEFEF'}}
                                onRangeChange={(e) => setDate(e)}
                            />
                        </div>
                    </>
                )
            } else {

            }


        }

    } else {
        let TMPTimeHolder = []
        TMPTimeHolder.push(
            {
                "title": 'test',
                "start": new Date(2017, 10, 0, 5, 0, 0),
                "end": new Date(2017, 10, 0, 21, 0, 0),
                "allday": true
            }
        )
        CalenderHolder.push(
            <>
                <div style={{float: 'left', marginRight: '15px', marginBottom: '20px'}}>
                    <p>Username: {user[0].name}</p>

                    <Calendar
                        messages={messages}
                        localizer={localizer}
                        startAccessor="start"
                        min={new Date(2017, 10, 0, 5, 0, 0)}
                        max={new Date(2017, 10, 0, 21, 0, 0)}
                        endAccessor="end"
                        views={['work_week', 'month']}
                        defaultView={'work_week'}
                        events={TMPTimeHolder}
                        style={{maxWidth: "750px", minWidth: '750px', float: 'left', background: '#EFEFEF'}}
                        onRangeChange={(e) => setDate(e)}
                    />
                </div>
            </>
        )
    }

    BuildCalenderDynamik.push(CalenderHolder)

    const NewMeetingForm = [
        {
            formType: 'input',
            label: IntlMessageValue('de', 'common.title', Store),
            required: true,
            type: 'text',
            name: 'title',
            id:"newCalenderTitle",
            value: ''
        },
        {
            formType: 'MultiAdded',
            addedfunc:setx,
            addedvalue:x,
            color:' rgba(19, 94, 126, 0.8)',
            icon:'add_circle',
            firstID:'newMember[0]',
            inputId: 'addMember',
            inputName:'member',
            injecktelement:[
                {
                    formType: 'fetchselect',
                    local: 'AllUSerCalendder',
                    valueState: 'id',
                    beginn: 'true',
                    start: 0,
                    name: 'newMember[added]',
                    labelState: 'username',
                    id:'newMember[added]',
                    selected: 0,
                    label: 'Teilnehmer[header]'
                }]
        },
        {
            formType: 'input',
            label: 'Start Datum',
            required: true,
            type: 'datetime-local',
            name: 'start',
            id:"newCalenderStart",
            value: ''
        },
        {
            formType: 'input',
            label: 'End Datum',
            required: true,
            type: 'datetime-local',
            name: 'end',
            id:"newCalenderEnd",
            value: ''
        },
        {
            formType: 'textarea',
            label:  IntlMessageValue('de', 'common.description', Store),
            type: 'textarea',
            max: 800,
            name: 'description',
            id: 'newCalenderDescription',
            placeholder: '',
            row:6,
            value: ''
        }
    ]
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlesubmit = (e:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        Data.newMember = Object.values(Data.newMember)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(Data);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"calender/task/0/", requestOptions)
            .then(response => response.text())
            .then(result => successForm(result))
            .catch(error => errorForm(error));
    };

    const successForm = (result:any) => {
        handleClose()
    }

    const errorForm = (error:any)=> {
        handleClose()
    }

    if (response.length > 0) {


        return (
            <>

                {shoDeviceUrl && OutlookCalenderDeviceRequest(22, DeviceUrl, deviceCode, 'Geräte Freischalt link', Store, setShoDeviceUrl, switchToShowDeviceCOde)}

                <div className="actions"
                     style={{ padding: "1em 0 "}}>
                    {check.state === 0 &&
                        <Button variant="primary" onClick={() => {
                            connectDevice()
                        }}>
                            <IntlMessage Store={Store} messageId="kalender.connect.button.label"/>
                        </Button>
                    }

                    <DynamikModal openButtonLabel={IntlMessageValue('de', 'common.calender.new.termin', Store)}
                                  saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                                  closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                                  title={IntlMessageValue('de', 'common.calender.new.termin', Store)}
                                  ClassOpenButton={'float-end'}
                                  ClassSaveButton={'float-end'}
                                  body={PielersForm(NewMeetingForm)}
                                  handlesubmit={handlesubmit}
                                  handleShow={handleShow}
                                  show={show}
                                  setShow={setShow}
                                  handleClose={handleClose}
                    />

                </div>

                <div className="myCustomHeight"
                     style={{padding: "1em 0 ",overflow:'hidden'}}>
                    <div  className={'mb-3'}>{USerHolderCheck}</div>
                    <div style={{width: '5000px',overflow:'scroll'}} >
                    {BuildCalenderDynamik}

                    </div>
                </div>

            </>
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default PageOverview


