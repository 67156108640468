import {SensorCCActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const SensorCCRequestInit = () => {
    return {
        type:SensorCCActionType.SensorCC_INIT
    }
}

export const SensorCCSuccess = (
    objeckt: any
) => {
    return {
        type: SensorCCActionType.SensorCC_INIT,
        objeckt: objeckt
    }
}

export const getSensorCC = (
    objeckt: any[]
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            SensorCCSuccess(
                objeckt
            )
        )
    }
}
