import React from 'react';
import DashbaordOverview from "./Pages/overview";

export const DashbaordPagesConfig = (value: any) => {

    return [
        {
            path: '/',
            elements: <DashbaordOverview value={value}/>,
            roles: ['superadmin','admin',
                'engineer',
                'locksmith',
                'consultant']
        },
    ];
}

export const DashbaordMenuConfig = (value: any) => {
    return [
            {header: 'Content',
                icon: 'shopping_cart',
                roles: [
                    'superadmin',
                    'admin',
                    'operator',
                    'merchant',
                    'merchant_ma',
                    'engineer',
                    'locksmith',
                    'consultant'
                ],
                menuitem: [{
                name: 'Dashboard',
                route: '/',
                icon: 'dashboard',
                roles: ['superadmin','admin',
                    'engineer',
                    'locksmith',
                    'consultant']
                }
                ]
            },
        ]
}
