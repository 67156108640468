import React, { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import home from './elements/home'
import { calenderApiDetail } from '../API/request/calender/calenderApiDetail.interface'
import { init2 } from '../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { config, output } from './configuration/mapping'
import { notify_save } from '../../../@WUM/core/component/const/notification.service'
import { error } from '../../../@WUM/core/component/const/error.services'
import { btn_back } from '../../../@WUM/core/component/const/btn_back'
import { handleSubmitForm } from '../../../@WUM/core/component/const/handleSubmitForm'
import { Form } from 'react-bootstrap'
import IntlMessage from '../../../@WUM/core/component/const/IntlMessage'
import { calender } from '../API/request/calender/calender'
import { useSelector } from 'react-redux'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import {secure} from "../../serviceauftrag/Pages/configuration/home";

const PageNew = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const history = useNavigate()
    const [x, setx] = useState(1)
    const [currentTutorial, setCurrentTutorial] = useState<calenderApiDetail[]>(
        []
    )
    /*  const [element,setElement] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);*/

    const [validated, setValidated] = useState(false)
    /*  const [seo_title, setseo_title] = useLocalStorage("seo_title", "");
  const [seo_description, setseo_description] = useLocalStorage("seo_description", "");*/
    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     * @param form
     */
    const updateData = (status: boolean, id: any = 0, form: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form)

        let reauestData: calenderApiDetail[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        calender
            .updatePostNew(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save()
               setTimeout(
                    () => (window.location.href = '/customer/overview/1'),
                    3000
                )
            })
            .catch((e) => {
                error(e)
            })
    }

    return (
        <div>
            <Form
                id={'form001'}
                className={'eventsCalender'}
                noValidate
                validated={validated}
                onSubmit={(e) =>
                    handleSubmitForm(e, updateData, setValidated, 0)
                }
            >
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="home"
                        title={
                            <IntlMessage
                                messageId="common.customer.settings"
                                Store={Store}
                            />
                        }
                        key={'allgemein'}
                        id={'allgemein'}
                        style={{
                            backgroundColor: '#EFEFEF',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {home('new', Store,x, setx)}
                    </Tab>
                    {/*<Tab eventKey="remind"
                         title={'Belege'}
                         key={'Belege'}
                         id={'Belege'}
                         style={{
                             backgroundColor: '#EFEFEF',
                             padding: '15px',
                             marginTop: '-15px'
                         }}>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>St</th>
                                <th>Title</th>
                                <th>Projekt</th>
                                <th>Nummer</th>
                                <th>Datum</th>
                                <th>Vorhaben</th>
                                <th>Betreff</th>
                                <th>Netto</th>
                                <th>MwSt.</th>
                                <th>Brutto</th>
                                <th>Währ.</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td></td>
                                <td>Angebot</td>
                                <td>TAGLOHN</td>
                                <td>20230704</td>
                                <td>27.10.23</td>
                                <td>KLÄRANLAGE</td>
                                <td>Austausch Motor</td>
                                <td>2.477,77</td>
                                <td>470,78</td>
                                <td>2.948,55</td>
                                <td>EUR</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Angebot</td>
                                <td>TAGLOHN</td>
                                <td>20230676</td>
                                <td>23.10.23</td>
                                <td>T160000</td>
                                <td>RÜB Ringwiese</td>
                                <td>1.700,74</td>
                                <td>323,14</td>
                                <td>2.023,88</td>
                                <td>EUR</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Tab>*/}
                    <Tab eventKey="setting"
                         title={'Eigenschaften'}
                         key={'Eigenschaften'}
                         id={'Eigenschaften'}
                         style={{
                             backgroundColor: '#EFEFEF',
                             padding: '15px',
                             marginTop: '-15px'
                         }}>
                        <Row>
                            <Col>
                                <Card className={'p-3'}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">Suchen</InputGroup.Text>
                                        <Form.Control
                                            name={'SUCHEN'}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">Briefanrede</InputGroup.Text>
                                        <Form.Control
                                            name={'BrAnrede'}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">z.H. Anrede</InputGroup.Text>
                                        <Form.Control
                                            name={'zuHaenden'}
                                        />
                                    </InputGroup>
                                    <Row>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">Postfach</InputGroup.Text>
                                                <Form.Control
                                                    name={'Psf'}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">PLZ</InputGroup.Text>
                                                <Form.Control
                                                    name={'Plz'}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                </Card>
                            </Col>
                            <Col>
                                <Card  className={'p-3'}>
                                    <Row>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">Selection</InputGroup.Text>
                                                <Form.Control
                                                    name={'Selection1'}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    name={'Selection2'}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">Branche</InputGroup.Text>
                                        <Form.Control
                                            name={'Branche'}
                                        />
                                    </InputGroup>
                                    <Row>
                                        <Col className={'col-9'}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">Einstufung</InputGroup.Text>
                                                <Form.Select aria-label="Default select example">
                                                    <option label={'Bitte Einstufung wählen'}></option>
                                                    <option value="kein Status">kein Status</option>
                                                    <option value="Sonstiges">- Sonstiges</option>
                                                    <option value="A – Interessent">A – Interessent</option>
                                                    <option value="B – Interessent">B – Interessent</option>
                                                    <option value="C – Interessent">C – Interessent</option>
                                                    <option value="Unwichtig">Unwichtig</option>
                                                    <option value="Wartung">Wartung</option>
                                                    <option value="Stammkunde">Stammkunde</option>
                                                    <option value="Kunde">Kunde</option>
                                                    <option value="Zahlt nicht">Zahlt nicht</option>
                                                    <option value="Gericht">Gericht</option>
                                                    <option value="Titel/Konkurs">Titel/Konkurs</option>
                                                    <option value="Privat">Privat</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">Achtung</InputGroup.Text>
                                                    <Form.Select aria-label="Default select example" name={'SpKzJn'} >
                                                        <option value="0">Nein</option>
                                                        <option value="1">Ja</option>
                                                    </Form.Select>
                                                </InputGroup>

                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className={'col-9'}>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">Ust.IDent</InputGroup.Text>
                                                <Form.Control
                                                    name={'UstId1'}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">§13b</InputGroup.Text>
                                                <Form.Select aria-label="Default select example" name={'KzPreisPfl'}>
                                                    <option value="0">Nein</option>
                                                    <option value="1">Ja</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card  className={'mt-3 p-3'}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Bemerkung</Form.Label>
                                        <Form.Control  name={'remarks'} as="textarea" rows={3} />
                                    </Form.Group>
                                </Card>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
                <div style={{ backgroundColor: '#EFEFEF', padding: '15px' }}>
                    {btn_back(history, 'common.back', '', Store)}
                    <Button
                        variant="success"
                        style={{ float: 'right', marginRight: '15px' }}
                        type={'submit'}
                    >
                        <IntlMessage messageId="common.save" Store={Store} />
                    </Button>
                    <br style={{ clear: 'both' }} />
                </div>
            </Form>
        </div>
    )
}

export default PageNew
