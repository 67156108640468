import {KdActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const KdRequestInit = () => {
    return {
        type:KdActionType.Kd_INIT
    }
}

export const KdSuccess = (
    objeckt: any
) => {
    return {
        type: KdActionType.Kd_INIT,
        objeckt: objeckt
    }
}

export const getKd = (
    objeckt: any[]
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            KdSuccess(
                objeckt
            )
        )
    }
}
