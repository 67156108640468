import {IntlMessageValue} from "../../../../@WUM/core/component/const/IntlMessage";

const Riso = (conf: string,
             response: any,
             newElement: any = false,
             Store: any,
              setcurrenttab:any) => {
    return [
        {
            formType: 'header',
            value: IntlMessageValue('de', 'common.riso.tab.header', Store),
        },
        {
            formType: 'textarea',
            label: IntlMessageValue('de', 'common.riso.tab.remarks', Store),
            type: 'textarea',
            max: 800,
            disabled: newElement === false ? true : false,
            name: 'vde[RisoBem]',
            id: 'seo_title',
            placeholder: '',
            value:
                newElement === false
                    ? response !== undefined && response !== null
                        ? response.vde.RisoBem
                        : ''
                    : ''
        },
        {
            formType: 'multiply',
            class:'mb-5',
            cols: [
                {
                    formType: 'clear',
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.riso.tab.limit', Store),
                    type: 'text',
                    placeholder: '',
                    readonly: newElement === false ? true : false,
                    name: 'vde[RisoGw]',
                    value: newElement === false ? response.vde.RisoGw : ''
                },
                {
                    formType: 'input',
                    label: IntlMessageValue('de', 'common.riso.tab.measured_value', Store),
                    type: 'text',
                    placeholder: '',
                    readonly: newElement === false ? true : false,
                    name: 'vde[RisoMw]',
                    value: newElement === false ? response.vde.RisoMw : ''
                },
                {
                    formType: 'select',
                    label: IntlMessageValue('de', 'common.riso.tab.measurement_passed', Store),
                    name: 'vde[Riso]',
                    disabled: newElement === false ? true : false,
                    selected: newElement === false ? response.vde.Riso : 0,
                    options: [

                        {
                            label: '',
                            value: ''
                        },
                        {
                            label: IntlMessageValue('de', 'common.riso.tab.measurement_passed.yes', Store),
                            value: 'WAHR'
                        },
                        {
                            label: IntlMessageValue('de', 'common.riso.tab.measurement_passed.no', Store),
                            value: ''
                        }
                    ]
                },

            ]
        },
        {
            formType: 'buttonTabeMaker',
            next:'allgemein',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.vde.tab.showcheck.button.general', Store),
        },
        {
            formType: 'buttonTabeMaker',
            next:'Auswertung',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.vde.tab.evaluation', Store),
            class: 'ml-3'
        },
        {
            formType: 'buttonTabeMaker',
            next:'skii',
            setter:setcurrenttab,
            label: IntlMessageValue('de', 'common.riso.tab.button.protection_class_II', Store),
            class: 'float-end mr-3'
        },
        {
            formType: 'buttonTabeMaker',
            next:'ski',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.riso.tab.button.protection_class_I', Store),
            class: 'float-end mr-3'
        },
        {
            formType: 'description',
            value: 'lang[common.tab.maker.riso.data.info]'
        }
    ]
}

export default Riso;
