import {IntlMessageValue} from "../../../../@WUM/core/component/const/IntlMessage";

const showCheck = (conf: string,
                 response: any,
                 newElement: any = false,
                 Store: any,
                   setcurrenttab:any) => {
    return [
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'select',
                    label: IntlMessageValue('de', 'common.vde.tab.showcheck.Visual_inspection_passed', Store),
                    name: 'vde[SichtP]',
                    disabled: newElement === false ? true : false,
                    selected: newElement === false ? response.vde.SichtP : 0,
                    options: [

                        {
                            label: '',
                            value: ''
                        },
                        {
                            label: IntlMessageValue('de', 'common.vde.tab.showcheck.Visual_inspection_passed.yes', Store),
                            value: 'WAHR'
                        },
                        {
                            label: IntlMessageValue('de', 'common.vde.tab.showcheck.Visual_inspection_passed.no', Store),
                            value: ''
                        }
                    ]
                },
                {
                    formType: 'textarea',
                    label: IntlMessageValue('de', 'common.vde.tab.showcheck.remarks', Store),
                    type: 'textarea',
                    max: 800,
                    name: 'vde[SichtPBem]',
                    disabled: newElement === false ? true : false,
                    id: 'seo_title',
                    placeholder: '',
                    value:
                        newElement === false
                            ? response !== undefined && response !== null
                                ? response.vde.SichtPBem
                                : ''
                            : ''
                },

            ]
        },{
            formType: 'buttonTabeMaker',
            next:'allgemein',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.vde.tab.showcheck.button.general', Store),
        },
        {
            formType: 'buttonTabeMaker',
            next:'Auswertung',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.vde.tab.evaluation', Store),
            class: 'ml-3'
        },
        {
            formType: 'buttonTabeMaker',
            next:'skii',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.vde.tab.showcheck.button.protection_class_II', Store),
            class: 'float-end mr-3'
        },
        {
            formType: 'buttonTabeMaker',
            next:'ski',
            setter:setcurrenttab,
            label:IntlMessageValue('de', 'common.vde.tab.showcheck.button.protection_class_I', Store),
            class: 'float-end mr-3'
        },
        {
            formType: 'description',
            value: 'lang[common.tab.maker.showcheck.data.info]'
        }

    ]
}

export default showCheck;
