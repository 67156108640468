import { useEffect, useState } from 'react'
import { calenderApiType } from '../../request/calender/calenderApi.interface'
import { calender } from '../../request/calender/calender'
import { calenderApiDetail } from '../../request/calender/calenderApiDetail.interface'
import { error } from '../../../../../@WUM/core/component/const/error.services'

import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../store'
import * as MotorCC from '../../../../store/actions/serviceauftrag/MotorCCAction'
import * as MotorWC from '../../../../store/actions/serviceauftrag/MotorWCAction'
import * as CompleteSystemCC from '../../../../store/actions/serviceauftrag/CompleteSystemCCAction'
import * as CompleteSystemWC from '../../../../store/actions/serviceauftrag/CompleteSystemWCAction'
import * as ElectronicsCC from '../../../../store/actions/serviceauftrag/ElectronicsCCAction'
import * as ElectronicsWC from '../../../../store/actions/serviceauftrag/ElectronicsWCAction'
import * as OotherSCCC from '../../../../store/actions/serviceauftrag/OotherSCCCAction'
import * as OotherSCWC from '../../../../store/actions/serviceauftrag/OotherSCWCAction'
import * as PumpeCC from '../../../../store/actions/serviceauftrag/PumpeCCAction'
import * as PumpeWC from '../../../../store/actions/serviceauftrag/PumpeWCAction'
import * as SensorCC from '../../../../store/actions/serviceauftrag/SensorCCAction'
import * as SensorWC from '../../../../store/actions/serviceauftrag/SensorWCAction'
import * as SoftwareCC from '../../../../store/actions/serviceauftrag/SoftwareCCAction'
import * as SoftwareWC from '../../../../store/actions/serviceauftrag/SoftwareWCAction'
import * as Objects  from '../../../../store/actions/serviceauftrag/ObjectsAction'
import * as Application from '../../../../store/actions/serviceauftrag/ApplicationAction'
import * as Action from '../../../../store/actions/serviceauftrag/ActionAction'
import * as Country from '../../../../store/actions/serviceauftrag/CountryAction'
import * as Environment from '../../../../store/actions/serviceauftrag/EnvironmentAction'
import * as Symptom from '../../../../store/actions/serviceauftrag/SymptomAction'
import * as Producer from '../../../../store/actions/serviceauftrag/ProducerAction'
import * as Agd from '../../../../store/actions/serviceauftrag/AgdAction'
import * as Kd from '../../../../store/actions/serviceauftrag/KdAction'
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";

export const ResponseCalender = (limit: string, searchValue: any) => {
    const [posts, setPosts] = useState<calenderApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
                .getPosts(limit, searchValue)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

export const ResponseCalenderDetail = (id: any) => {
    const [posts, setPosts] = useState<calenderApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
                .getAPost(id)
                .then((data) => {
                    checkLogin(data)
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}

export const ResponseCalenderDelete = (id: any) => {
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
                .deletePost(id)
                .then((data) => {
                    checkLogin(data)
                    return data
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : isError
}

export const ResponseFetchDataset= (type: any,SetWaitStore:any) => {
    const [posts, setPosts] = useState<calenderApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await calender
                .fetchDataset(type)
                .then((data) => {
                    switch (type) {
                        case 'Producer':
                            dispatch(Producer.getProducer(data))
                            break;
                        case 'Symptom':
                            dispatch(Symptom.getSymptom(data))
                            break;
                        case 'Environment':
                            dispatch(Environment.getEnvironment(data))
                            break;
                        case 'Country':
                            dispatch(Country.getCountry(data))
                            break;
                        case 'Action':
                            dispatch(Action.getAction(data))
                            SetWaitStore(true)
                            break;
                        case 'Application':
                            dispatch(Application.getApplication(data))
                            break;
                        case 'objects':
                            dispatch(Objects.getObjects(data))
                            break;
                        case 'PumpeWC':
                            dispatch(PumpeWC.getPumpeWC(data))
                            break;
                        case 'MotorWC':
                            dispatch(MotorWC.getMotorWC(data))
                            break;
                        case 'electronicsWC':
                            dispatch(ElectronicsWC.getElectronicsWC(data))
                            break;
                        case 'SensorWC':
                            dispatch(SensorWC.getSensorWC(data))
                            break;
                        case 'ootherSCWC':
                            dispatch(OotherSCWC.getOotherSCWC(data))
                            break;
                        case 'softwareWC':
                            dispatch(SoftwareWC.getSoftwareWC(data))
                            break;
                        case 'CompleteSystemWC':
                            dispatch(CompleteSystemWC.getCompleteSystemWC(data))
                            break;
                        case 'PumpeCC':
                            dispatch(PumpeCC.getPumpeCC(data))
                            break;
                        case 'MotorCC':
                            dispatch(MotorCC.getMotorCC(data))
                            break;
                        case 'electronicsCC':
                            dispatch(ElectronicsCC.getElectronicsCC(data))
                            break;
                        case 'SensorCC':
                            dispatch(SensorCC.getSensorCC(data))
                            break;
                        case 'ootherSCCC':
                            dispatch(OotherSCCC.getOotherSCCC(data))
                            break;
                        case 'softwareCC':
                            dispatch(SoftwareCC.getSoftwareCC(data))
                            break;
                        case 'CompleteSystemCC':
                            dispatch(CompleteSystemCC.getCompleteSystemCC(data))
                            break;
                    }
                    checkLogin(data)
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch,type,SetWaitStore])

    return isError ? posts : posts
}

export const ResponseFetchGeneralDataset= (type: any) => {
    const [posts, setPosts] = useState<calenderApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
                .fetchDataset(type)
                .then((data) => {
                    checkLogin(data)
                    localStorage.setItem(type, JSON.stringify(data))
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [type])

    return isError ? posts : posts
}

export const ResponseFetchAGDDataset= (type: any,kdId?:any) => {
    const [posts, setPosts] = useState<calenderApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await calender
                .fetchKDDataset(type)
                .then((data) => {
                    checkLogin(data)
                    dispatch(Agd.getAgd(data))

                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch])

    return isError ? posts : posts
}

export const ResponseFetchKDDataset= (type: any,kdId?:any) => {
    const [posts, setPosts] = useState<calenderApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await calender
                .fetchAGDDataset(type,kdId)
                .then((data) => {
                    checkLogin(data)
                    dispatch(Kd.getKd(data))
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch,kdId])

    return isError ? posts : posts
}

export const ResponsefetchUserDataset = (discr: any,noneLocal?:any) => {
    const [posts, setPosts] = useState<calenderApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await calender
                .fetchUserDataset(discr)
                .then((data) => {
                    checkLogin(data)
                    //localStorage.setItem('AllUSerSa', JSON.stringify(data))
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch])

    return isError ? posts : posts
}
