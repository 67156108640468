import React, { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'
import home from './elements/home'
import { init2 } from '../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { config, output } from './configuration/mapping'
import { error } from '../../../@WUM/core/component/const/error.services'
import { btn_back } from '../../../@WUM/core/component/const/btn_back'
import { handleSubmitForm } from '../../../@WUM/core/component/const/handleSubmitForm'
import { Form } from 'react-bootstrap'
import IntlMessage from '../../../@WUM/core/component/const/IntlMessage'
import { ResponseCalenderDetail } from '../API/response/calender/calender'
import { calenderApiDetail } from '../API/request/calender/calenderApiDetail.interface'
import { calender } from '../API/request/calender/calender'
import { useSelector } from 'react-redux'
import { SpinnerJSX } from '../../../@WUM/core/component/const/Spinner'

const PageDetail = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const { id } = useParams<{ id: string }>()
    const response2 = ResponseCalenderDetail(id)
    const [currenttab, setcurrenttab] = useState('allgemein');
    const history = useNavigate()

    const [currentTutorial, setCurrentTutorial] =
        useState<calenderApiDetail[]>(response2)

    const [validated, setValidated] = useState(false)

    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     * @param form
     */
    const updateData = (status: boolean, id: any, form: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form)

        let reauestData: calenderApiDetail[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        calender
            .updatePost(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                /* notify_save_intvall_reload();*/
                $('#btn_save')[0].setAttribute('disabled', 'disbaled')
                setTimeout(() => window.location.reload(), 3000)
            })
            .catch((e) => {
                error(e)
            })
    }
    if (response2.length > 0) {
    return (
        <div>
            <Form
                id={'form001'}
                className={'eventsCalender'}
                noValidate
                validated={validated}
                onSubmit={(e) =>
                    handleSubmitForm(e, updateData, setValidated, id)
                }
            >
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="home"
                        title={
                            <IntlMessage
                                messageId="common.vde"
                                Store={Store}
                            />
                        }
                        key={'allgemein'}
                        id={'allgemein'}
                        style={{
                            backgroundColor: '#EFEFEF',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {home((response2.length !== undefined ? response2[0] : response2), Store,currenttab, setcurrenttab)}
                    </Tab>
                </Tabs>
                <div style={{ backgroundColor: '#EFEFEF', padding: '15px' }}>
                    {btn_back(history, 'common.back', '', Store)}
                    <Button
                        variant="success"
                        id={'btn_save'}
                        style={{ float: 'right', marginRight: '15px' }}
                        type={'submit'}
                    >
                        <IntlMessage messageId="common.save" Store={Store} />
                    </Button>
                    <br style={{ clear: 'both' }} />
                </div>
            </Form>
        </div>
    )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default PageDetail

export const displayPill = (options: { id:any,from:any,to:any}) => {

    console.log(document.getElementsByClassName('TableMaker')[0])
}
