import {ElectronicsWCActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const ElectronicsWCRequestInit = () => {
    return {
        type: ElectronicsWCActionType.ElectronicsWC_INIT
    }
}

export const ElectronicsWCSuccess = (
    objeckt: any
) => {
    return {
        type: ElectronicsWCActionType.ElectronicsWC_INIT,
        objeckt: objeckt
    }
}

export const getElectronicsWC = (
    objeckt: any[]
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            ElectronicsWCSuccess(
                objeckt
            )
        )
    }
}
