import {SoftwareCCActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const SoftwareCCRequestInit = () => {
    return {
        type:SoftwareCCActionType.SoftwareCC_INIT
    }
}

export const SoftwareCCSuccess = (
    objeckt: any
) => {
    return {
        type: SoftwareCCActionType.SoftwareCC_INIT,
        objeckt: objeckt
    }
}

export const getSoftwareCC = (
    objeckt: any[]
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            SoftwareCCSuccess(
                objeckt
            )
        )
    }
}
