import { configureStore } from '@reduxjs/toolkit'
import PageReducer from './Reducers/Pages/PagesReducer'
import ProductReducer from './Reducers/Product/ProductReducer'
import LocaleReducer from '../@WUM/templates/default/localization/store/Reducers/Locale/LocaleReducer'
import UserReducer from '../@WUM/templates/default/Auth/store/reducer/User/UserReducer'
import DynamikModulsReducer from '../@WUM/templates/default/Auth/store/reducer/dynamikModuls/dynamikModulsReducer'
import CompleteSystemCCReducer from "../modules/store/reducer/serviceauftrag/CompleteSystemCC";
import CompleteSystemWCReducer from "../modules/store/reducer/serviceauftrag/CompleteSystemWC";
import ElectronicsCCReducer from "../modules/store/reducer/serviceauftrag/electronicsCC";
import ElectronicsWCReducer from "../modules/store/reducer/serviceauftrag/electronicsWC";
import MotorCCReducer from "../modules/store/reducer/serviceauftrag/MotorCC";
import MotorWCReducer from "../modules/store/reducer/serviceauftrag/MotorWC";
import OotherSCCCReducer from "../modules/store/reducer/serviceauftrag/ootherSCCC";
import OotherSCWCReducer from "../modules/store/reducer/serviceauftrag/ootherSCWC";
import PumpeCCReducer from "../modules/store/reducer/serviceauftrag/PumpeCC";
import PumpeWCReducer from "../modules/store/reducer/serviceauftrag/PumpeWC";
import SensorCCReducer from "../modules/store/reducer/serviceauftrag/SensorCC";
import SensorWCReducer from "../modules/store/reducer/serviceauftrag/SensorWC";
import SoftwareCCReducer from "../modules/store/reducer/serviceauftrag/softwareCC";
import SoftwareWCReducer from "../modules/store/reducer/serviceauftrag/softwareWC";
import SymptomReducer from "../modules/store/reducer/serviceauftrag/Symptom";
import ProducerReducer from "../modules/store/reducer/serviceauftrag/Producer";
import ObjectsReducer from "../modules/store/reducer/serviceauftrag/Objects";
import KdReducer from "../modules/store/reducer/serviceauftrag/Kd";
import EnvironmentReducer from "../modules/store/reducer/serviceauftrag/Environment";
import CountryReducer from "../modules/store/reducer/serviceauftrag/Country";
import ApplicationReducer from "../modules/store/reducer/serviceauftrag/Application";
import AgdReducer from "../modules/store/reducer/serviceauftrag/Agd";
import ActionReducer from "../modules/store/reducer/serviceauftrag/Action";

const store = configureStore({
    reducer: {
        page: PageReducer,
        product: ProductReducer,
        locale: LocaleReducer,
        user: UserReducer,
        dynamikModuls: DynamikModulsReducer,
        CompleteSystemCC:CompleteSystemCCReducer,
        CompleteSystemWC:CompleteSystemWCReducer,
        ElectronicsCC:ElectronicsCCReducer,
        ElectronicsWC:ElectronicsWCReducer,
        MotorCC:MotorCCReducer,
        MotorWC:MotorWCReducer,
        OotherSCCC:OotherSCCCReducer,
        OotherSCWC:OotherSCWCReducer,
        PumpeCC:PumpeCCReducer,
        PumpeWC:PumpeWCReducer,
        SensorCC:SensorCCReducer,
        SensorWC:SensorWCReducer,
        SoftwareCC:SoftwareCCReducer,
        SoftwareWC:SoftwareWCReducer,
        Symptom: SymptomReducer,
        Producer:ProducerReducer,
        Objects:ObjectsReducer,
        Kd:KdReducer,
        Environment:EnvironmentReducer,
        Country:CountryReducer,
        Application:ApplicationReducer,
        Agd:AgdReducer,
        Action:ActionReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
