export interface config {
    AUFTRAGNR: string
    DATUM?: any
    Seite: string
    AG_KDNR: string
    KD_KDNR?: any
    agd_name: string
    agd_tel1: string
    agd_fax: string
    agd_mail: string
    Kundenbestellung: string
    Kundenbestelldatum: string
    kd_please_contact: string
    kd_tel: string
    kd_tel2: string
    kd_mail: string
    FEHLERANGA: string
    PRODUKTNR: string
    PRODUKT: string
    Application: string
    Action: string
    Producer: string
    Objects: string
    PumpeWC: string
    Symptom: string
    PumpeCC: string
    Environment: string
    PC: string
    Country: string
    Seriennummer: string
    Angebot: string
    PRODCODE:string,
    sa_gefordert: string
    auftrag_complete: string
    Servicetechniker1: string
    Servicetechniker2: string
    Servicetechniker3: string
    Arbeitszeit: string
    Fahrzeit: string
    Fahrkilometer: string
    id: string
    NL_VB_VBA: string
    BESTELLDAT: string
    MOTOR_TYP: string
    PRODFAM1: string
    PRODUKTNUMMER: string
    SERIENNR: string
    HERSTELLER: string
    TECHNIKER: string
    TECHNIKER2: string
    TECHNIKER3: string
    AUFTRAGSAR: string
    INTERNEVER: string
    WO: string
    WAS: string
    WARUM: string
    WO2: string
    WAS2: string
    WARUM2: string
    ANWENDUNG: string
    OBJEKT: string
    ARTIKEL1: string
    MATERIAL1: string
    ANZAH1: string
    CODE1:string
    ARTIKEL2: string
    MATERIAL2: string
    ANZAHL2: string
    CODE2:string
    ARTIKEL3: string
    MATERIAL3: string
    ANZAHL3: string
    CODE3:string
    ARTIKEL4: string
    MATERIAL4: string
    ANZAHL4: string
    CODE4:string
    ARTIKEL5: string
    MATERIAL5: string
    ANZAHL5: string
    CODE5:string
    ARBEITSZEIT: string
    FAHRZEIT: string
    ZONE: string
    KM: string
    Lagerplatz: string
    FOTODOK: string
    produktFam: string,
    STATUS: string,
    FOLGEAUFTR: string,
    TECHNIKERB: string,
    TECHNIKERB1: string,
    SERVICEBER:string,
    signature:string,
    signature_date: string;
    PRODSTELLE:string
    TERMIN:string
    IHRE_BESTE:string
    GWS_SA:string
    pdf_city:string
}

export function output(Data: any) {
    let back = {
        AUFTRAGNR: Data.AUFTRAGNR,
        DATUM: Data.DATUM,
        Seite: Data.Seite,
        AG_KDNR: Data.AG_KDNR.split(' - ')[0],
        KD_KDNR: Data.KD_KDNR.split(' - ')[0],
        agd_name: Data.agd_name,
        agd_tel1: Data.agd_tel1,
        agd_fax: Data.agd_fax,
        agd_mail: Data.agd_mail,
        Kundenbestellung: Data.Kundenbestellung,
        Kundenbestelldatum: Data.Kundenbestelldatum,
        kd_please_contact: Data.kd_please_contact,
        kd_tel: Data.kd_tel,
        kd_tel2: Data.kd_tel2,
        kd_mail: Data.kd_mail,
        FEHLERANGA: Data.FEHLERANGA,
        PRODUKTNR: Data.PRODUKTNR,
        PRODUKT: Data.PRODUKT,
        Application: Data.Application,
        Action: Data.Action,
        Producer: Data.Producer,
        Objects: Data.Objects,
        PumpeWC: Data.PumpeWC,
        Symptom: Data.Symptom,
        PumpeCC: Data.PumpeCC,
        Environment: Data.Environment,
        PC: Data.PC,
        Country: Data.Country,
        Seriennummer: Data.Seriennummer,
        FOTODOK: Data.FOTODOK,
        Angebot: Data.Angebot,
        sa_gefordert: Data.sa_gefordert,
        auftrag_complete: Data.auftrag_complete,
        Servicetechniker1: Data.Servicetechniker1,
        Servicetechniker2: Data.Servicetechniker2,
        Servicetechniker3: Data.Servicetechniker3,
        Arbeitszeit: Data.Arbeitszeit,
        Fahrzeit: Data.Fahrzeit,
        Fahrkilometer: Data.Fahrkilometer,
        id: Data.id,
        NL_VB_VBA: Data.NL_VB_VBA,
        BESTELLDAT: Data.BESTELLDAT,
        MOTOR_TYP: Data.MOTOR_TYP,
        PRODFAM1: Data.PRODFAM1,
        PRODUKTNUMMER: Data.PRODUKTNUMMER,
        SERIENNR: Data.SERIENNR,
        HERSTELLER: Data.HERSTELLER,
        TECHNIKER: Data.TECHNIKER,
        TECHNIKER2: Data.TECHNIKER2,
        TECHNIKER3: Data.TECHNIKER3,
        AUFTRAGSAR: Data.AUFTRAGSAR,
        INTERNEVER: Data.INTERNEVER,
        WO: Data.WO,
        WAS: Data.WAS,
        WARUM: Data.WARUM,
        WO2: Data.WO2,
        WAS2: Data.WAS2,
        WARUM2: Data.WARUM2,
        ANWENDUNG: Data.ANWENDUNG,
        OBJEKT: Data.OBJEKT,
        ARTIKEL1: Data.ARTIKEL1,
        MATERIAL1: Data.MATERIAL1,
        ANZAH1: Data.ANZAH1,
        CODE1: Data.CODE1,
        ARTIKEL2: Data.ARTIKEL2,
        MATERIAL2: Data.MATERIAL2,
        ANZAHL2: Data.ANZAHL2,
        CODE2: Data.CODE2,
        ARTIKEL3: Data.ARTIKEL3,
        MATERIAL3: Data.MATERIAL3,
        ANZAHL3: Data.ANZAHL3,
        CODE3: Data.CODE3,
        ARTIKEL4: Data.ARTIKEL4,
        MATERIAL4: Data.MATERIAL4,
        ANZAHL4: Data.ANZAHL4,
        CODE4: Data.CODE4,
        ARTIKEL5: Data.ARTIKEL5,
        MATERIAL5: Data.MATERIAL5,
        ANZAHL5: Data.ANZAHL5,
        CODE5: Data.CODE5,
        ARBEITSZEIT: Data.ARBEITSZEIT,
        FAHRZEIT: Data.FAHRZEIT,
        ZONE: Data.ZONE,
        KM: Data.KM,
        Lagerplatz: Data.Lagerplatz,
        produktFam: Data.produktFam,
        STATUS: Data.STATUS,
        FOLGEAUFTR:Data.FOLGEAUFTR,
        TECHNIKERB: Data.TECHNIKERB,
        TECHNIKERB1: Data.TECHNIKERB1,
        SERVICEBER: Data.SERVICEBER,
        signature: Data.signature,
        PRODSTELLE:Data.PRODSTELLE,
        PRODCODE:Data.PRODCODE,
        TERMIN:Data.TERMIN,
        IHRE_BESTE:Data.IHRE_BESTE,
        GWS_SA:Data.GWS_SA,
        pdf_city:Data.pdf_city,
        signature_date: Data.signature_date
    }

    return back
}
