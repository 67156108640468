import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import Cards from '../../../../@WUM/core/component/const/dashboardCards'
import configruation from '../configuration'
import React from "react";
import {Link} from "react-router-dom";

const DashbaordOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)


    let dashboard = []
    let conf = configruation(Store)
    for (let x = 0; x < conf.length; x++) {
        if (conf[x].onClick !== undefined) {
            dashboard.push(
                <>
                    <div className={'float-start'}>
                       <Link to={conf[x].href} className={'text-white text-decoration-none'}><span className="material-icons aIcon" aria-hidden="true">
                        { conf[x].icon !== undefined && conf[x].icon !== null
                            ? conf[x].icon
                            : 'login'}
                       </span><br/>
                       <label>{conf[x].title}</label>
                       </Link>
                    </div>
                </>
            )
        } else {
            dashboard.push(
                <>
                    <div className={'float-start'}>
                        <Link to={conf[x].href} className={'text-white text-decoration-none aIcon'} style={{display:'block'}}
                        title={conf[x].text}
                        ><span className="material-icons fs85" aria-hidden="true">
                          { conf[x].icon !== undefined && conf[x].icon !== null
                              ? conf[x].icon
                              : 'login'}
                     </span><br/>
                            <label>{conf[x].title}</label>
                        </Link>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <div className={'DashbaordRow text-center'} style={{background:'#EFEFEF',borderRadius: '15px'}}>{dashboard}
            <div style={{clear:'both'}} />
            </div>
        </>
    )
}

export default DashbaordOverview



