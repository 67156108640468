import {EnvironmentActionType} from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const EnvironmentRequestInit = () => {
    return {
        type:EnvironmentActionType.Environment_INIT
    }
}

export const EnvironmentSuccess = (
    objeckt: any
) => {
    return {
        type: EnvironmentActionType.Environment_INIT,
        objeckt: objeckt
    }
}

export const getEnvironment = (
    objeckt: any[]
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            EnvironmentSuccess(
                objeckt
            )
        )
    }
}
