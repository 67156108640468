import {IntlMessageValue} from "../../../../@WUM/core/component/const/IntlMessage";

const table = (conf: string,
               response: any,
               newElement: any = false,
               Store: any) => {
    return [
        {
            formType: 'input',
            label: IntlMessageValue('de', 'common.table.tab.test_device1', Store),
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.table.tab.test_device1.provide', Store),
            name: 'Pruefgeraet1',
            value: newElement === false ? response.Pruefgeraet1 : ''
        }
    ]
}

export default table;
